<template>
  <div class="app-alerts">
    <Transition
      v-for="{ timestamp, title, content, type } of alertsStore.allAlertsOrderedByTimestamp"
      :key="timestamp"
      name="swish"
      mode="in-out">
      <AppMessage
        v-bind="{ title, content, type }"
        can-close
        @closed="alertsStore.HIDE_ALERT({ type, timestamp })"/>
    </Transition>
  </div>
</template>

<script setup>
import { useAlertsStore } from '@/stores'

const alertsStore = useAlertsStore()
</script>

<style lang="scss" scoped>
.swish-enter-active,
.swish-leave-active {
  transition: all 0.75s ease;
}

.swish-enter-from,
.swish-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}

.app-alerts {
  display: flex;
  position: absolute;
  z-index: 100;
  top: 1em;
  right: 1em;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 1em;
}
</style>
