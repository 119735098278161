// https://supabase.com/docs/reference/javascript/select
import { supabase, parseRes, getAllRecords } from '@/utils/api/db/config'
import listings from '@/utils/mockData/listings.json'

export default {
  /* eslint sort-keys: "error" */

  async getCharacterListings ({ characterId, battlenetId }) {
    const res = await supabase()
      .from('listing')
      .select('*, provided_reagent_ids:listing_provided_reagent(reagent_id)')
      .eq('character_id', characterId)
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async getCharacters ({ battlenetId = null, characterIds = null } = {}) {
    const whereClauses = []

    if (!_.isNull(characterIds)) {
      whereClauses.push({ column: 'id', type: 'in', value: characterIds })
    }

    if (!_.isNull(battlenetId)) {
      whereClauses.push({ column: 'battlenet_id', type: 'eq', value: battlenetId })
    }

    const res = await getAllRecords({
      ...{
        from: 'character',
        select: '*',
      },
      ...(!_.isEmpty(whereClauses) ? { where: whereClauses } : {}),
    })
    return parseRes(res)
  },

  getFakeListings () {
    return _.$camelCaseKeys(listings)
  },

  async getFavouriteCrafters () {
    const res = await getAllRecords({ from: 'favourite_crafter' })
    return parseRes(res)
  },

  async getFavouriteRealms () {
    const res = await getAllRecords({ from: 'favourite_realm' })
    return parseRes(res)
  },

  async getFavouriteRecipes () {
    const res = await getAllRecords({ from: 'favourite_recipe' })
    return parseRes(res)
  },

  async getHolidayMode ({ battlenetId }) {
    const res = await supabase()
      .from('holiday_mode')
      .select()
      .eq('battlenet_id', battlenetId)
    return !!parseRes(res).data.length
  },

  async getItem ({ id }) {
    const res = await supabase()
      .from('item')
      .select()
      .eq('id', id)
    return parseRes(res)
  },

  async getItems () {
    const res = await getAllRecords({ from: 'item' })
    return parseRes(res)
  },

  async getListings ({ realmId, connectedRealmId, recipeId }) {
    const res = await getAllRecords({
      from: 'listing',
      select: `
        id,
        is_active,
        base_price,
        base_concentration_price,
        free_concentration,
        can_provide_non_tier,
        can_provide_tier_1,
        can_provide_tier_2,
        can_provide_tier_3,
        non_tier_price,
        tier_1_price,
        tier_2_price,
        tier_3_price,
        character_id,
        created_at,
        updated_at,
        skill,
        multicraft_percent,
        recipe_id,
        recipe!inner(
          id,
          deleted_at
        ),
        provided_reagent_ids:listing_provided_reagent(reagent_id),
        character!inner(
          id,
          last_online_at,
          name,
          class_slug,
          realm_id,
          is_favourite,
          on_holiday,
          faction,
          realm!inner(
            id,
            connected_realm_id
          ),
          error_started_at
        )
      `,
      where: [
        _.isUndefined(connectedRealmId)
          ? { column: 'character.realm.id', type: 'eq', value: realmId }
          : { column: 'character.realm.connected_realm_id', type: 'eq', value: connectedRealmId },
        { column: 'recipe_id', type: 'eq', value: recipeId },
        { column: 'character.on_holiday', type: 'eq', value: false },
        { column: 'is_active', type: 'eq', value: true },
        { column: 'recipe.deleted_at', type: 'is', value: null },
      ],
    })
    return parseRes(res)
  },

  async getModifiedReagentReagents () {
    const res = await getAllRecords({ from: 'modified_reagent_reagent' })
    return parseRes(res)
  },

  async getModifiedReagents () {
    const res = await getAllRecords({ from: 'modified_reagent' })
    return parseRes(res)
  },

  async getProfessionRecipesAndRelations ({ id }) {
    const res = await supabase()
      .from('recipe')
      .select(`
        *,
        tier_id:profession_tier_id,
        item!recipe_item (*),
        reagents:recipe_reagent (
          *,
          item (*)
        ),
        modified_reagents:recipe_modified_reagent (
          *,
          modified_reagent (
            name,
            items:item (*)
          )
        )
      `)
      .eq('profession_id', id)
      .is('deleted_at', null)
    return parseRes(res)
  },

  async getReagent ({ id }) {
    const res = await supabase()
      .from('reagent')
      .select()
      .eq('id', id)
    return parseRes(res)
  },

  async getReagents () {
    const res = await getAllRecords({ from: 'reagent' })
    return parseRes(res)
  },

  async getRealms () {
    const res = await getAllRecords({ from: 'realm' })
    return parseRes(res)
  },

  async getRecipe ({ id }) {
    const res = await supabase()
      .from('recipe')
      .select()
      .eq('id', id)
      .is('deleted_at', null)
    return parseRes(res)
  },

  async getRecipeAndRelations ({ id }) {
    const res = await supabase()
      .from('recipe')
      .select(`
        *,
        recipe_item!inner (
          item (*)
        ),
        recipe_reagents: recipe_reagent (
          *,
          item (*)
        ),
        recipe_modified_reagents: recipe_modified_reagent (
          *,
          modified_reagent (
            name,
            items: item (*)
          )
        )
      `)
      .eq('id', id)
      .is('deleted_at', null)
    if (res.data) {
      res.data = res.data?.[0]
      if (_.isUndefined(res.data)) return parseRes(res)
      res.data.items = res.data.recipe_item.map(({ item }) => item)
      delete res.data.recipe_item
    }
    return parseRes(res)
  },

  async getRecipeItems () {
    const res = await getAllRecords({ from: 'recipe_item', select: '*, recipe(deleted_at)', where: [{ column: 'deleted_at', type: 'is', value: null }] })
    return parseRes(res)
  },

  async getRecipeModifiedReagents () {
    const res = await getAllRecords({ from: 'recipe_modified_reagent', select: '*, recipe(deleted_at)', where: [{ column: 'deleted_at', type: 'is', value: null }] })
    return parseRes(res)
  },

  async getRecipeReagents () {
    const res = await getAllRecords({ from: 'recipe_reagent', select: '*, recipe(deleted_at)', where: [{ column: 'deleted_at', type: 'is', value: null }] })
    return parseRes(res)
  },

  async getRecipes ({ itemMustExist = true } = {}) {
    const res = await getAllRecords(itemMustExist
      ? { from: 'recipe', select: '*, recipe_item!inner(*, item(*))', where: [{ column: 'deleted_at', type: 'is', value: null }] }
      : { from: 'recipe', select: '*, recipe_item(*, item(*))', where: [{ column: 'deleted_at', type: 'is', value: null }] })
    return parseRes(res)
  },

  async getRecipesAndRelations () {
    const res = await getAllRecords({
      from: 'recipe',
      select: `
        *,
        item:recipe_item (
          *,
          item (*)
        ),
        reagents:recipe_reagent (
          *,
          item (*)
        ),
        modified_reagents:recipe_modified_reagent (
          *,
          modified_reagent (
            *,
            items:item (*)
          )
        )
      `,
      where: [{ column: 'deleted_at', type: 'is', value: null }],
    })
    return parseRes(res)
  },

  async getSiteSettings () {
    const res = await getAllRecords({ from: 'site_settings' })
    return parseRes(res)
  },
}
