import { createClient } from '@supabase/supabase-js'
import { useUserStore } from '@/stores'
import AuthError from '@/utils/errors/AuthError'
import { getSetCache } from '@/utils/helpers/cache'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY

const PER_PAGE = 1000
export function supabase () {
  const { auth, isAuthenticated } = useUserStore()

  const authConfig = {
    global: {
      headers: {
        Authorization: `Bearer ${auth.jwt}`,
      },
    },
  }

  return isAuthenticated
    ? getSetCache('supabase_client', auth.jwt, () => createClient(supabaseUrl, supabaseKey, authConfig))
    : getSetCache('supabase_client', 'anon', () => createClient(supabaseUrl, supabaseKey, {}))
}

export function parseRes (res = {}) {
  if (_.inRange(res.status, 200, 299)) {
    const { count, data = count, status, statusText } = res
    return {
      count,
      data: _.$camelCaseKeys(data),
      status,
      statusText,
      ok: true,
    }
  }

  const { status, statusText, error } = res

  if (_.includes([401, 403], res.status)) throw new AuthError()

  return {
    errors: error,
    status,
    statusText,
    ok: false,
  }
}

export function getPagination (page, size = PER_PAGE) {
  const from = page * size
  const to = ((page + 1) * size) - 1

  return [from, to]
}

export async function getAllRecords ({ from, select = '*', where = [] }) {
  let count = 0
  let response = {}

  for (let page = 0; page < Infinity; page++) {
    const query = supabase()
      .from(from)
      .select(select)
      .range(...getPagination(page, PER_PAGE))

    if (!_.isEmpty(where)) {
      for (const wherePart of where) {
        query[wherePart.type](wherePart.column, wherePart.value)
      }
    }

    const { error, data, ...res } = await query

    if (error) return { ...res, error }

    if (!data) break

    if (page === 0) response = { ...res, data }
    else response.data = _.concat(response.data, data)

    count += data.length
    if (data.length < PER_PAGE) break
  }

  return { ...response, count }
}
