import api from '@/utils/api'
import { useUserStore } from '@/stores'

export default {
  state: () => ({
    isMaintenanceMode: JSON.parse(import.meta.env.VITE_MAINTENANCE_MODE ?? 'null'),
    showDevThings: JSON.parse(localStorage.getItem('ff-dev-btns') ?? 'false'),
  }),

  getters: {
    isDevMode: () => {
      const isDevMode = ['development', 'staging'].includes(process.env.NODE_ENV)
      const { isAuthenticated, auth }  = useUserStore()
      const { VITE_BATTLENET_ADMIN_ACCOUNTS } = import.meta.env
      if (!isDevMode || !isAuthenticated) return false
      return VITE_BATTLENET_ADMIN_ACCOUNTS?.split(',')?.includes(auth.battleTag)
    },
    isOverridingMaintenanceMode: () => _.isString(import.meta.env.VITE_MAINTENANCE_MODE),
  },

  actions: {
    async CHECK_MAINTENANCE_MODE () {
      if (this.isOverridingMaintenanceMode) return

      const { data } = await api.db.getSiteSettings()
      const siteSettings = data.reduce((acc, { key, value }) => ({
        ...acc,
        [_.camelCase(key)]: /(true|false)/.test(value)
          ? JSON.parse(value)
          : value,
      }), {})
      this.setMaintenanceMode(siteSettings.isSiteReadonly)
    },

    setMaintenanceMode (newVal) {
      this.isMaintenanceMode = newVal
    },

    toggleShowDevThings () {
      this.showDevThings = !this.showDevThings
      localStorage.setItem('ff-dev-btns', this.showDevThings)
    },
  },
}
