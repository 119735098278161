<template>
  <a
    :class="['donate-link', { 'donate-link--expanded': expanded }]"
    :href="platformLink"
    target="_blank">
    <img
      v-if="platform === 'kofi'"
      src="/images/kofi-logo.png"
      :alt="`${platformName} logo`"
      class="donate-link__image">
    <AppIcon
      v-else
      :name="platform"
      :size="22"/>
    <span class="donate-link__text">Support us on {{ platformName }}</span>
  </a>
</template>

<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      validator: (platform) => ['patreon', 'kofi'].includes(platform),
      required: true,
    },
  },

  computed: {
    platformName () {
      return {
        kofi: 'Ko-fi',
        patreon: 'Patreon',
      }[this.platform]
    },

    platformLink () {
      return {
        kofi: 'https://ko-fi.com/forgefinder',
        patreon: 'https://patreon.com/ForgeFinder',
      }[this.platform]
    },
  },
}
</script>

<style lang="scss" scoped>
.donate-link {
  display: inline-flex;
  gap: 2px;
  z-index: 1;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 4px;
  padding: 0 4px !important;
  transition: width 0.5s ease;
  border-radius: 4px;
  background: rgb(var(--slate));
  color: rgb(var(--text)) !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &__image {
    width: auto;
    height: 30px;
  }

  &__text {
    max-width: 0;
    max-height: 1em;
    overflow: hidden;
    transition: max-width 0.5s ease;
    line-height: 1em;
    white-space: pre;
  }

  &--expanded,
  &:hover {
    gap: 6px;
    width: 193px;
    transition: width 0.5s ease;
    background: linear-gradient(to right, rgb(var(--slate)), rgb(var(--black)));

    .donate-link__text {
      max-width: unset;
    }
  }
}
</style>
