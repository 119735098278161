<template>
  <a
    ref="tooltip"
    v-bind="$attrs"
    :data-wowhead="dataWowhead"
    @click.prevent>
    <slot/>
  </a>
</template>

<script>
export default {
  props: {
    dataWowhead: {
      type: String,
      required: true,
    },
  },

  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    rerenderTooltip () {
      this.$refs.tooltip.classList.remove('fade-in')
      this.$refs.tooltip.removeAttribute('data-wh-icon-added')
      this.$refs.tooltip.classList.remove('q1', 'q2', 'q3', 'q4', 'q5')
      window.WH.Tooltips.refreshLinks()
      this.$refs.tooltip.classList.add('fade-in')
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

a {
  display: inline-flex;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;

  &.fade-in {
    animation: fade-in 0.3s ease-in-out;
  }
}

</style>
