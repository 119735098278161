import { getExpansionFromRecipe } from '@/utils/config/professions'
import df from '@/utils/config/expansions/dragonflight/reagentExclusionGroups'
import tww from '@/utils/config/expansions/thewarwithin/reagentExclusionGroups'

const expansionSpecificExclusionMappings = {
  df,
  tww,
}

export function getExclusionGroups (optionId, { professionId, professionTierId }) {
  const expansion = getExpansionFromRecipe({ professionId, professionTierId }).key ?? null

  if (expansion !== null) {
    const exclusionMapping = expansionSpecificExclusionMappings?.[expansion.toLowerCase()] ?? null
    if (!exclusionMapping) return null

    return exclusionMapping?.[optionId] ?? null
  }

  return null
}
