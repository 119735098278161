// https://supabase.com/docs/reference/javascript/update
// import { supabase, parseRes } from '@/utils/api/db/config'

import { parseRes, supabase } from '@/utils/api/db/config'

export default {
  /* eslint sort-keys: "error" */

  async updateRecipeModifiedReagents (recipeModifiedReagents) {
    const res = await supabase()
      .from('recipe_modified_reagent')
      .update(_.isArray(recipeModifiedReagents) ? _.map(recipeModifiedReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipeModifiedReagents))
    return parseRes(res)
  },

  async updateRecipeReagents (recipeReagents) {
    const res = await supabase()
      .from('recipe_reagent')
      .update(_.isArray(recipeReagents) ? _.map(recipeReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipeReagents))
    return parseRes(res)
  },

  async updateRecipes (recipes) {
    const res = await supabase()
      .from('recipe')
      .update(_.isArray(recipes) ? _.map(recipes, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipes))
    return parseRes(res)
  },
}
