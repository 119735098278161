import {
  GROUP_GRANT_PVP_ITEM_LEVEL,
  GROUP_GRANT_PVP_ITEM_LEVEL_2,
  GROUP_GRANT_PVP_ITEM_LEVEL_3,
  GROUP_INFUSE_WITH_POWER,
  GROUP_INFUSE_WITH_POWER_2,
  GROUP_SPARK,
  ITEM_CONCENTRATED_PRIMAL_INFUSION,
  ITEM_DRACONIC_CREST_OF_HONOR,
  ITEM_DRACONIC_TROPHY_OF_CONQUEST,
  ITEM_ENCHANTED_ASPECTS_AWAKENED_CREST,
  ITEM_ENCHANTED_ASPECTS_DREAMING_CREST,
  ITEM_ENCHANTED_ASPECTS_SHADOWFLAME_CREST,
  ITEM_ENCHANTED_WYRMS_AWAKENED_CREST,
  ITEM_ENCHANTED_WYRMS_DREAMING_CREST,
  ITEM_ENCHANTED_WYRMS_SHADOWFLAME_CREST,
  ITEM_GREATER_DRACONIC_CREST_OF_HONOR,
  ITEM_GREATER_DRACONIC_TROPHY_OF_CONQUEST,
  ITEM_GREATER_OBSIDIAN_CREST_OF_HONOR,
  ITEM_GREATER_OBSIDIAN_TROPHY_OF_CONQUEST,
  ITEM_GREATER_VERDANT_CREST_OF_HONOR,
  ITEM_GREATER_VERDANT_TROPHY_OF_CONQUEST,
  ITEM_LESSER_DRACONIC_CREST_OF_HONOR,
  ITEM_LESSER_DRACONIC_TROPHY_OF_CONQUEST,
  ITEM_LESSER_OBSIDIAN_CREST_OF_HONOR,
  ITEM_LESSER_OBSIDIAN_TROPHY_OF_CONQUEST,
  ITEM_LESSER_VERDANT_CREST_OF_HONOR,
  ITEM_LESSER_VERDANT_TROPHY_OF_CONQUEST,
  ITEM_OBSIDIAN_CREST_OF_HONOR,
  ITEM_OBSIDIAN_TROPHY_OF_CONQUEST,
  ITEM_PRIMAL_INFUSION,
  ITEM_SPARK_OF_AWAKENING,
  ITEM_SPARK_OF_DREAMS,
  ITEM_SPARK_OF_INGENUITY,
  ITEM_SPARK_OF_SHADOWFLAME,
  ITEM_VERDANT_CREST_OF_HONOR,
  ITEM_VERDANT_TROPHY_OF_CONQUEST,
} from '@/utils/config/expansions/dragonflight/reagents'

export default {
  [ITEM_PRIMAL_INFUSION]: {
    exclusiveIds: [ITEM_SPARK_OF_INGENUITY],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_CONCENTRATED_PRIMAL_INFUSION]: {
    exclusiveIds: [ITEM_SPARK_OF_INGENUITY],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_ENCHANTED_WYRMS_SHADOWFLAME_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_ENCHANTED_ASPECTS_SHADOWFLAME_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_ENCHANTED_WYRMS_DREAMING_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_ENCHANTED_ASPECTS_DREAMING_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_ENCHANTED_WYRMS_AWAKENED_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_ENCHANTED_ASPECTS_AWAKENED_CREST]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_OBSIDIAN_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_GREATER_OBSIDIAN_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_LESSER_OBSIDIAN_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_OBSIDIAN_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_GREATER_OBSIDIAN_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_LESSER_OBSIDIAN_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_SHADOWFLAME],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_VERDANT_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_GREATER_VERDANT_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_LESSER_VERDANT_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_VERDANT_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_GREATER_VERDANT_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_LESSER_VERDANT_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_DREAMS],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_DRACONIC_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_GREATER_DRACONIC_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_LESSER_DRACONIC_CREST_OF_HONOR]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_DRACONIC_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_GREATER_DRACONIC_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },
  [ITEM_LESSER_DRACONIC_TROPHY_OF_CONQUEST]: {
    exclusiveIds: [ITEM_SPARK_OF_AWAKENING],
    groupIds: [GROUP_SPARK],
  },

  [ITEM_SPARK_OF_INGENUITY]: {
    exclusiveIds: [
      ITEM_CONCENTRATED_PRIMAL_INFUSION,
      ITEM_PRIMAL_INFUSION,
    ],
    groupIds: [
      GROUP_INFUSE_WITH_POWER,
      GROUP_INFUSE_WITH_POWER_2,
      GROUP_GRANT_PVP_ITEM_LEVEL,
      GROUP_GRANT_PVP_ITEM_LEVEL_2,
      GROUP_GRANT_PVP_ITEM_LEVEL_3,
    ],
  },

  [ITEM_SPARK_OF_SHADOWFLAME]: {
    exclusiveIds: [
      ITEM_ENCHANTED_WYRMS_SHADOWFLAME_CREST,
      ITEM_ENCHANTED_ASPECTS_SHADOWFLAME_CREST,

      ITEM_OBSIDIAN_CREST_OF_HONOR,
      ITEM_GREATER_OBSIDIAN_CREST_OF_HONOR,
      ITEM_LESSER_OBSIDIAN_CREST_OF_HONOR,

      ITEM_OBSIDIAN_TROPHY_OF_CONQUEST,
      ITEM_GREATER_OBSIDIAN_TROPHY_OF_CONQUEST,
      ITEM_LESSER_OBSIDIAN_TROPHY_OF_CONQUEST,
    ],
    groupIds: [
      GROUP_INFUSE_WITH_POWER,
      GROUP_INFUSE_WITH_POWER_2,
      GROUP_GRANT_PVP_ITEM_LEVEL,
      GROUP_GRANT_PVP_ITEM_LEVEL_2,
      GROUP_GRANT_PVP_ITEM_LEVEL_3,
    ],
  },

  [ITEM_SPARK_OF_DREAMS]: {
    exclusiveIds: [
      ITEM_ENCHANTED_WYRMS_DREAMING_CREST,
      ITEM_ENCHANTED_ASPECTS_DREAMING_CREST,

      ITEM_VERDANT_CREST_OF_HONOR,
      ITEM_GREATER_VERDANT_CREST_OF_HONOR,
      ITEM_LESSER_VERDANT_CREST_OF_HONOR,

      ITEM_VERDANT_TROPHY_OF_CONQUEST,
      ITEM_GREATER_VERDANT_TROPHY_OF_CONQUEST,
      ITEM_LESSER_VERDANT_TROPHY_OF_CONQUEST,
    ],
    groupIds: [
      GROUP_INFUSE_WITH_POWER,
      GROUP_INFUSE_WITH_POWER_2,
      GROUP_GRANT_PVP_ITEM_LEVEL,
      GROUP_GRANT_PVP_ITEM_LEVEL_2,
      GROUP_GRANT_PVP_ITEM_LEVEL_3,
    ],
  },

  [ITEM_SPARK_OF_AWAKENING]: {
    exclusiveIds: [
      ITEM_ENCHANTED_WYRMS_AWAKENED_CREST,
      ITEM_ENCHANTED_ASPECTS_AWAKENED_CREST,

      ITEM_DRACONIC_CREST_OF_HONOR,
      ITEM_GREATER_DRACONIC_CREST_OF_HONOR,
      ITEM_LESSER_DRACONIC_CREST_OF_HONOR,

      ITEM_DRACONIC_TROPHY_OF_CONQUEST,
      ITEM_GREATER_DRACONIC_TROPHY_OF_CONQUEST,
      ITEM_LESSER_DRACONIC_TROPHY_OF_CONQUEST,
    ],
    groupIds: [
      GROUP_INFUSE_WITH_POWER,
      GROUP_INFUSE_WITH_POWER_2,
      GROUP_GRANT_PVP_ITEM_LEVEL,
      GROUP_GRANT_PVP_ITEM_LEVEL_2,
      GROUP_GRANT_PVP_ITEM_LEVEL_3,
    ],
  },
}
