export const GROUP_SPARK = 247
export const GROUP_INFUSE_WITH_POWER = 111
export const GROUP_INFUSE_WITH_POWER_2 = 126
export const GROUP_SOCKET = 251
export const GROUP_SOCKET_2 = 252
export const GROUP_GRANT_PVP_ITEM_LEVEL = 244
export const GROUP_GRANT_PVP_ITEM_LEVEL_2 = 245
export const GROUP_GRANT_PVP_ITEM_LEVEL_3 = 246

export const ITEM_PRIMAL_INFUSION = 197921
export const ITEM_CONCENTRATED_PRIMAL_INFUSION = 198046
export const ITEM_ENCHANTED_WYRMS_SHADOWFLAME_CREST = 204682
export const ITEM_ENCHANTED_ASPECTS_SHADOWFLAME_CREST = 204697
export const ITEM_ENCHANTED_WHELPLINGS_SHADOWFLAME_CREST = 204681
export const ITEM_ENCHANTED_WYRMS_DREAMING_CREST = 206960
export const ITEM_ENCHANTED_ASPECTS_DREAMING_CREST = 206961
export const ITEM_ENCHANTED_WHELPLINGS_DREAMING_CREST = 206977
export const ITEM_ENCHANTED_ASPECTS_AWAKENED_CREST = 211519
export const ITEM_ENCHANTED_WYRMS_AWAKENED_CREST = 211518
export const ITEM_ENCHANTED_WHELPLINGS_AWAKENED_CREST = 211520

export const ITEM_SPARK_OF_INGENUITY = 190453
export const ITEM_SPARK_OF_SHADOWFLAME = 204440
export const ITEM_SPARK_OF_DREAMS = 206959
export const ITEM_SPARK_OF_AWAKENING = 211516

export const ITEM_TITAN_TRAINING_MATRIX_V = 204673
export const ITEM_TITAN_TRAINING_MATRIX_IV = 198059
export const ITEM_TITAN_TRAINING_MATRIX_III = 198058
export const ITEM_TITAN_TRAINING_MATRIX_II = 198056
export const ITEM_TITAN_TRAINING_MATRIX_I = 198048

export const ITEM_OBSIDIAN_CREST_OF_HONOR = 204190
export const ITEM_GREATER_OBSIDIAN_CREST_OF_HONOR = 204189
export const ITEM_LESSER_OBSIDIAN_CREST_OF_HONOR = 204191

export const ITEM_OBSIDIAN_TROPHY_OF_CONQUEST = 204187
export const ITEM_GREATER_OBSIDIAN_TROPHY_OF_CONQUEST = 204186
export const ITEM_LESSER_OBSIDIAN_TROPHY_OF_CONQUEST = 204188

export const ITEM_VERDANT_CREST_OF_HONOR = 212540
export const ITEM_GREATER_VERDANT_CREST_OF_HONOR = 212541
export const ITEM_LESSER_VERDANT_CREST_OF_HONOR = 212539

export const ITEM_VERDANT_TROPHY_OF_CONQUEST = 212537
export const ITEM_GREATER_VERDANT_TROPHY_OF_CONQUEST = 212538
export const ITEM_LESSER_VERDANT_TROPHY_OF_CONQUEST = 212536

export const ITEM_DRACONIC_TROPHY_OF_CONQUEST = 211685
export const ITEM_GREATER_DRACONIC_TROPHY_OF_CONQUEST = 211686
export const ITEM_LESSER_DRACONIC_TROPHY_OF_CONQUEST = 211684

export const ITEM_DRACONIC_CREST_OF_HONOR = 211688
export const ITEM_GREATER_DRACONIC_CREST_OF_HONOR = 211689
export const ITEM_LESSER_DRACONIC_CREST_OF_HONOR = 211687
