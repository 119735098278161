import { getSetCache } from '@/utils/helpers/cache'
import { getExclusionGroups } from '@/utils/config/reagentExclusionGroups'

const materialOptions = [
  {
    value: 1,
    label: '<span class="tier">T1</span> Reagents',
    shortLabel: '<span class="tier">T1</span>',
  },
  {
    value: 2,
    label: '<span class="tier">T2</span> Reagents',
    shortLabel: '<span class="tier">T2</span>',
  },
  {
    value: 3,
    label: '<span class="tier">T3</span> Reagents',
    shortLabel: '<span class="tier">T3</span>',
  },
]
const noMaterialOptions = [
  {
    value: 1,
    label: 'Tiered reagents',
  },
]

const sortOptions = [
  {
    selectedLabel: 'Commission',
    selectedIcon: 'sortDsc',
    optionLabel: 'Commission <span class="sub-text">Hi-Lo</span>',
    optionIcon: 'sortDsc',
    field: ['price'],
    direction: ['desc'],
    value: 1,
  },
  {
    selectedLabel: 'Commission',
    selectedIcon: 'sortAsc',
    optionLabel: 'Commission <span class="sub-text">Lo-Hi</span>',
    optionIcon: 'sortAsc',
    field: ['price'],
    direction: ['asc'],
    value: 1,
  },
  {
    selectedLabel: 'Max Quality',
    selectedIcon: 'sortDsc',
    optionLabel: 'Max Quality <span class="sub-text">Hi-Lo</span>',
    optionIcon: 'sortDsc',
    field: ['quality'],
    direction: ['desc'],
    value: 1,
  },
  {
    selectedLabel: 'Max Quality',
    selectedIcon: 'sortAsc',
    optionLabel: 'Max Quality <span class="sub-text">Lo-Hi</span>',
    optionIcon: 'sortAsc',
    field: ['quality'],
    direction: ['asc'],
    value: 1,
  },
  {
    selectedLabel: 'Crafter Name',
    selectedIcon: 'sortDsc',
    optionLabel: 'Crafter Name <span class="sub-text">A-Z</span>',
    optionIcon: 'sortDsc',
    field: ['name'],
    direction: ['asc'],
    value: 1,
  },
  {
    selectedLabel: 'Crafter Name',
    selectedIcon: 'sortAsc',
    optionLabel: 'Crafter Name <span class="sub-text">Z-A</span>',
    optionIcon: 'sortAsc',
    field: ['name'],
    direction: ['desc'],
    value: 1,
  },
  // {
  //   selectedLabel: 'Multicraft %',
  //   selectedIcon: 'sortDsc',
  //   optionLabel: 'Multicraft % <span class="sub-text">Hi-Lo</span>',
  //   optionIcon: 'sortDsc',
  //   field: [ 'multicraftPercent'],
  //   direction: ['desc'],
  //   value: 1,
  // },
  // {
  //   selectedLabel: 'Multicraft %',
  //   selectedIcon: 'sortAsc',
  //   optionLabel: 'Multicraft % <span class="sub-text">Lo-Hi</span>',
  //   optionIcon: 'sortAsc',
  //   field: ['multicraftPercent'],
  //   direction: ['asc'],
  //   value: 1,
  // },
]

export function getSelectableModifiedReagentItem ({ reagent, filters }) {
  const { optionalReagentsIndex } = filters

  return getSetCache('getSelectableModifiedReagentItem', { ...filters, ...reagent }, () => {
    const modifiedReagentGroupId = reagent.modifiedReagentId

    if (_.isNil(optionalReagentsIndex?.[modifiedReagentGroupId])) return null

    const modifiedReagentGroup = reagent.modifiedReagent

    const optionalReagentIndex = optionalReagentsIndex[modifiedReagentGroupId]
    const optionalReagents = getOptionalReagentGroups({ filters })?.[modifiedReagentGroupId]
    const selectedOptionReagent = optionalReagents.items[optionalReagentIndex]
    const selectedModifiedReagent = _.find(modifiedReagentGroup.items, ['id', selectedOptionReagent.id])

    return !_.isEmpty(selectedModifiedReagent)
      ? selectedModifiedReagent
      : null
  })
}

export function getModifiedReagentItem ({ reagent, filters }) {
  const { tierIndex } = filters

  return getSetCache('getModifiedReagentItem', { ...filters, ...reagent }, () => {
    const modifiedReagentGroup = reagent.modifiedReagent
    const tierOptions = getTierOptions({ filters })
    const tier = tierOptions[tierIndex].value

    const modifiedReagent =  _.find(modifiedReagentGroup.items, ['tier', tier])

    return !_.isEmpty(modifiedReagent)
      ? modifiedReagent
      : null
  })
}

/**
 * Used with OptionalReagentSelectList.vue
 *
 * Checks if the passed option would be a valid selection
 * returns the group ids that would become invalid if this option was selected
 * otherwise returns null
 */
export function getInvalidGroup ({ option, groups, filters }) {
  if (!filters?.recipe) return null

  // if the option.id is found in exclusion list
  const exclusive = getExclusionGroups(option.id, filters.recipe)
  if (!exclusive) return null

  for (const reagentGroup of _.values(groups)) {
    const reagent = {
      modifiedReagentId: reagentGroup.id,
      modifiedReagent: {
        items: reagentGroup.items,
      },
    }

    // if this group is not one of the exclusive groups move onto the next
    if (!_.includes(exclusive.groupIds, reagentGroup.id)) continue

    const selectedItem = getSelectableModifiedReagentItem({ reagent, filters })

    if (selectedItem !== null && !_.includes(exclusive.exclusiveIds, selectedItem.id)) return exclusive.groupIds
  }

  return null
}

export function getSortOptions () {
  return sortOptions
}

export function getAllTierOptions () {
  return materialOptions
}

/**
 * A. Returns an array containing a single option of 'No tiered reagents' if this recipe has no modified reagents
 * B. Returns an array of all 3 tiers available for reagents if there are some modified reagents that are non-optional
 */
export function getTierOptions ({ filters }) {
  if (_.isEmpty(filters) || _.isEmpty(filters?.recipe?.recipeModifiedReagents)) return noMaterialOptions

  return getSetCache('getTierOptions', filters, () => {
    const qualityBasedReagents = _.filter(filters.recipe.recipeModifiedReagents, (reagent) => !reagent.optional)

    return _.isEmpty(qualityBasedReagents)
      ? noMaterialOptions
      : materialOptions
  })
}

export function getOptionalReagentGroups ({ filters }) {
  if (_.isEmpty(filters) || _.isEmpty(filters?.recipe?.recipeModifiedReagents)) return {}

  return _(filters.recipe.recipeModifiedReagents)
    .transform((acc, { crafterOnly, optional, order, modifiedReagent: { name, items }, modifiedReagentId }) => {
      if (!crafterOnly && optional) acc[modifiedReagentId] = {
        name,
        order,
        id: modifiedReagentId,
        items: _(items)
          .orderBy(({ name, tier }) => tier === null ? ['z', tier] : [name, tier], ['asc','desc'])
          .map(({ difficulty, name, tier, id, icon, quality }) => ({
            difficulty,
            label: `${name} (${tier})`,
            id,
            icon,
            quality,
            tier,
          }))
          .value(),
      }
    }, {})
    .value()
}
