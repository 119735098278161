// https://supabase.com/docs/reference/javascript/delete
import { supabase, parseRes } from '@/utils/api/db/config'

export default {
  /* eslint sort-keys: "error" */

  async removeCharacters ({ battlenetId }) {
    const res = await supabase()
      .from('character')
      .delete()
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async removeFavouriteCrafter ({ battlenetId, characterId }) {
    const res = await supabase()
      .from('favourite_crafter')
      .delete()
      .eq('battlenet_id', battlenetId)
      .eq('character_id', characterId)
    return parseRes(res)
  },

  async removeFavouriteCrafters ({ battlenetId }) {
    const res = await supabase()
      .from('favourite_crafter')
      .delete()
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async removeFavouriteRealm ({ battlenetId, realmId }) {
    const res = await supabase()
      .from('favourite_realm')
      .delete()
      .eq('battlenet_id', battlenetId)
      .eq('realm_id', realmId)
    return parseRes(res)
  },

  async removeFavouriteRealms ({ battlenetId }) {
    const res = await supabase()
      .from('favourite_realm')
      .delete()
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async removeFavouriteRecipe ({ battlenetId, recipeId }) {
    const res = await supabase()
      .from('favourite_recipe')
      .delete()
      .eq('battlenet_id', battlenetId)
      .eq('recipe_id', recipeId)
    return parseRes(res)
  },

  async removeFavouriteRecipes ({ battlenetId }) {
    const res = await supabase()
      .from('favourite_recipe')
      .delete()
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async removeHolidayMode ({ battlenetId }) {
    const res = await supabase()
      .from('holiday_mode')
      .delete()
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async removeItems (items) {
    const res = await supabase()
      .from('item')
      .delete()
      .in('id', _.map(items, 'id'))
    return parseRes(res)
  },

  async removeListingProvidedReagents (listingProvidedReagents) {
    const res = await supabase()
      .from('listing_provided_reagent')
      .delete()
      .in('listing_id', _.map(listingProvidedReagents, 'listingId'))
      .in('reagent_id',  _.map(listingProvidedReagents, 'reagentId'))
    return parseRes(res)
  },

  async removeListings (listings) {
    const res = await supabase()
      .from('listing')
      .delete()
      .in('id', _.map(listings, 'id'))
    return parseRes(res)
  },

  async removeListingsByBattlenetId ({ battlenetId }) {
    const res = await supabase()
      .from('listing')
      .delete()
      .eq('battlenet_id', battlenetId)
    return parseRes(res)
  },

  async removeModifiedReagentReagents (modifiedReagentReagents) {
    const res = await supabase()
      .from('modified_reagent_reagent')
      .delete()
      .in('modified_reagent_id', _.map(modifiedReagentReagents, 'modifiedReagentId'))
      .in('reagent_id', _.map(modifiedReagentReagents, 'reagentId'))
    return parseRes(res)
  },

  async removeModifiedReagents (modifiedReagents) {
    const res = await supabase()
      .from('modified_reagent')
      .delete()
      .in('id', _.map(modifiedReagents, 'id' ))
    return parseRes(res)
  },

  async removeRecipeItems (recipeItems) {
    const res = await supabase()
      .from('recipe_item')
      .delete()
      .in('recipe_id', _.map(recipeItems, 'recipeId'))
      .in('item_id', _.map(recipeItems, 'itemId'))
    return parseRes(res)
  },

  async removeRecipeModifiedReagents (recipeModifiedReagents) {
    const res = await supabase()
      .from('recipe_modified_reagent')
      .delete()
      .in('recipe_id', _.map(recipeModifiedReagents, 'recipeId'))
      .in('modified_reagent_id', _.map(recipeModifiedReagents, 'modifiedReagentId'))
    return parseRes(res)
  },

  async removeRecipeReagents (recipeReagents) {
    const res = await supabase()
      .from('recipe_reagent')
      .delete()
      .in('recipe_id', _.map(recipeReagents, 'recipeId'))
      .in('reagent_id', _.map(recipeReagents, 'reagentId'))
    return parseRes(res)
  },

  async removeRecipes (recipes, soft = true) {
    const res = soft
      ? await supabase()
        .from('recipe')
        .update({ deleted_at: (new Date()).toISOString().toLocaleString('en-GB') })
        .in('id', _.map(recipes, 'id'))
      : await supabase()
        .from('recipe')
        .delete()
        .in('id', _.map(recipes, 'id'))

    return parseRes(res)
  },
}
