const caches = {}
export function getSetCache (cache, key, getValue, { overwrite = false } = {}) {
  const strKey = JSON.stringify(key)

  if (!_.isFunction(getValue)) throw new Error('getValue must be of type function')

  if (!caches?.[cache]) caches[cache] = {}
  if (_.isUndefined(caches[cache]?.[strKey]) || overwrite) caches[cache][strKey] = getValue()
  return caches[cache]?.[strKey]
}

export async function getSetCacheAsync (cache, key, getValue, { overwrite = false } = {}) {
  const strKey = JSON.stringify(key)

  if (!_.isFunction(getValue)) throw new Error('getValue must be of type function')

  if (!caches?.[cache]) caches[cache] = {}
  if (_.isUndefined(caches[cache]?.[strKey]) || overwrite) caches[cache][strKey] = await getValue()
  return caches[cache][strKey]
}
