// https://supabase.com/docs/reference/javascript/upsert
import { supabase, parseRes } from '@/utils/api/db/config'

export default {
  /* eslint sort-keys: "error" */

  async upsertCharacters (characters) {
    const res = await supabase()
      .from('character')
      .upsert(_.isArray(characters) ? _.map(characters, _.$snakeCaseKeys) : _.$snakeCaseKeys(characters))
      .select()
    return parseRes(res)
  },

  async upsertFavouriteCrafter (favourite) {
    const res = await supabase()
      .from('favourite_crafter')
      .upsert(_.isArray(favourite) ? _.map(favourite, _.$snakeCaseKeys) : _.$snakeCaseKeys(favourite))
    return parseRes(res)
  },

  async upsertFavouriteRealm (favourite) {
    const res = await supabase()
      .from('favourite_realm')
      .upsert(_.isArray(favourite) ? _.map(favourite, _.$snakeCaseKeys) : _.$snakeCaseKeys(favourite))
    return parseRes(res)
  },

  async upsertFavouriteRecipe (favourite) {
    const res = await supabase()
      .from('favourite_recipe')
      .upsert(_.isArray(favourite) ? _.map(favourite, _.$snakeCaseKeys) : _.$snakeCaseKeys(favourite))
    return parseRes(res)
  },

  async upsertHolidayMode (holidayMode) {
    const res = await supabase()
      .from('holiday_mode')
      .upsert(_.isArray(holidayMode) ? _.map(holidayMode, _.$snakeCaseKeys) : _.$snakeCaseKeys(holidayMode))
    return parseRes(res)
  },

  async upsertItems (items) {
    const res = await supabase()
      .from('item')
      .upsert(_.isArray(items) ? _.map(items, _.$snakeCaseKeys) : _.$snakeCaseKeys(items))
    return parseRes(res)
  },

  async upsertListingProvidedReagents (providedReagents) {
    const res = await supabase()
      .from('listing_provided_reagent')
      .upsert(_.isArray(providedReagents) ? _.map(providedReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(providedReagents))
      .select()
    return parseRes(res)
  },

  async upsertListings (listings) {
    const res = await supabase()
      .from('listing')
      .upsert(_.isArray(listings) ? _.map(listings, _.$snakeCaseKeys) : _.$snakeCaseKeys(listings))
      .select()
    return parseRes(res)
  },

  async upsertModifiedReagentReagents (modifiedReagentReagents) {
    const res = await supabase()
      .from('modified_reagent_reagent')
      .upsert(_.isArray(modifiedReagentReagents) ? _.map(modifiedReagentReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(modifiedReagentReagents))
    return parseRes(res)
  },

  async upsertModifiedReagents (modifiedReagents) {
    const res = await supabase()
      .from('modified_reagent')
      .upsert(_.isArray(modifiedReagents) ? _.map(modifiedReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(modifiedReagents))
    return parseRes(res)
  },

  async upsertReagents (reagents) {
    const res = await supabase()
      .from('reagent')
      .upsert(_.isArray(reagents) ? _.map(reagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(reagents))
    return parseRes(res)
  },

  async upsertRealms (realms) {
    const res = await supabase()
      .from('realm')
      .upsert(_.isArray(realms) ? _.map(realms, _.$snakeCaseKeys) : _.$snakeCaseKeys(realms))
    return parseRes(res)
  },

  async upsertRecipeItems (recipeReagents) {
    const res = await supabase()
      .from('recipe_item')
      .upsert(_.isArray(recipeReagents) ? _.map(recipeReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipeReagents))
    return parseRes(res)
  },

  async upsertRecipeModifiedReagents (recipeModifiedReagents) {
    const res = await supabase()
      .from('recipe_modified_reagent')
      .upsert(_.isArray(recipeModifiedReagents) ? _.map(recipeModifiedReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipeModifiedReagents))
    return parseRes(res)
  },

  async upsertRecipeReagents (recipeReagents) {
    const res = await supabase()
      .from('recipe_reagent')
      .upsert(_.isArray(recipeReagents) ? _.map(recipeReagents, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipeReagents))
    return parseRes(res)
  },

  async upsertRecipes (recipes) {
    const res = await supabase()
      .from('recipe')
      .upsert(_.isArray(recipes) ? _.map(recipes, _.$snakeCaseKeys) : _.$snakeCaseKeys(recipes))
    return parseRes(res)
  },
}
