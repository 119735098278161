<template>
  <component
    v-bind="$attrs"
    :style="`
      --btn-bg-color: ${themeVar};
      --btn-bg-color-hover: ${themeVarOnHover};
    `"
    :class="[`button button--${theme}`, {
      'button--outlined': outlined,
    }]"
    :is="tag">
    <AppIcon
      v-if="loading"
      class="loading-icon"
      loading
      :name="loadingIcon"/>
    <slot/>
  </component>
</template>

<script>
export const themes = [
  'black',
  'blue',
  'gold',
  'green',
  'grey',
  'orange',
  'red',
  'slate',
]

export default {
  name: 'AppButton',

  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      validator: (theme) => themes.includes(theme),
      default: 'gold',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingIcon: {
      type: String,
      default: 'loader',
    },
  },

  computed: {
    themeVar () {
      if (this.outlined) return `var(--${this.theme})`
      return {
        gold: `var(--${this.theme}-dark-1)`,
      }[this.theme] ?? `var(--${this.theme})`
    },

    themeVarOnHover () {
      if (this.outlined) return `var(--${this.theme}) / 0.2`
      return {
        slate: `var(--grey-dark-1)`,
        black: `var(--blue-dark-1)`,
      }[this.theme] ?? `var(--${this.theme}-light-1)`
    },

    tag () {
      if (this.$attrs.to) return 'router-link'
      if (this.$attrs.href) return 'a'
      return 'button'
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0.5em 1.25em;
  transition:
    color 0.25s ease-in,
    border-color 0.25s ease-in,
    background-color 0.25s ease-in;
  border: 1px solid rgb(var(--btn-bg-color));
  border-radius: 0.25em;
  outline: none;
  background-color: rgb(var(--btn-bg-color));
  color: white;
  font-family: QuickSand, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
  cursor: pointer;
  gap: 2px;

  &:is(:disabled) {
    --btn-bg-color: var(--grey-dark-1) !important;

    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: rgb(var(--btn-bg-color-hover));
    }
  }

  &--outlined {
    background: none;
    color: rgb(var(--btn-bg-color));

    &:not([disabled]) {
      &:hover,
      &:focus {
        border-color: currentColor;
        background: rgb(var(--btn-bg-color-hover));
        color: rgb(var(--btn-bg-color));
      }
    }
  }

  &--blue:not(.button--outlined) {
    :deep(.loading-icon *) {
      color: rgb(var(--white));
    }
  }

  &--black.button:not(.button--outlined) {
    color: rgb(var(--white));

    &:not([disabled]) {
      &:hover,
      &:focus {
        color: rgb(var(--blue));
      }
    }
  }

  &--grey.button.button--outlined {
    &:not([disabled]) {
      &:hover,
      &:focus {
        color: rgb(var(--grey-light-1) / 0.75);
      }
    }
  }
}

.loading-icon {
  margin-right: 0.5em;
}
</style>
