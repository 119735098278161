<template>
  <div
    :class="['quality-icon', {
      'quality-icon--with-concentration': needsConcentration && !hideConcentration,
      'quality-icon--concentration-not-offered': needsConcentration && hideConcentration,
    }]">
    <template v-if="!needsConcentration">
      <img
        class="quality-icon__image"
        :style="`--quality-icon-size: var(--icon-size, ${size}px);`"
        :src="`images/rank${rank}.png`"
        v-bind="{ alt }">
    </template>

    <template v-else>
      <template v-if="hideConcentration">
        <AppIcon
          :size="20"
          class="quality-icon__concentration-icon"
          name="sparkles"/>
        <span class="x">⨯</span>
      </template>

      <template v-else>
        <img
          :class="['quality-icon__image quality-icon__image--with-concentration', {
            'quality-icon__image--with-concentration-number': showConcentrationNumber,
          }]"
          :style="`--quality-icon-size: var(--icon-size, ${size}px);`"
          :src="`images/rank${rank}.png`"
          v-bind="{ alt }">
        <span
          v-if="showConcentrationNumber && concentrationNumber"
          class="quality-icon__concentration-number">
          {{ concentrationNumber }}
          <span class="quality-icon__concentration-percent"/>
        </span>
        <AppIcon
          :size="12"
          class="quality-icon__concentration-icon"
          name="sparkles"/>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    rank: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 40,
    },
    alt: {
      type: String,
      default: ({ rank }) => `quality ${rank}`,
    },
    needsConcentration: {
      type: Boolean,
      default: false,
    },
    hideConcentration: {
      type: Boolean,
      default: false,
    },
    concentrationNumber: {
      type: Number,
      default: null,
    },
    showConcentrationNumber: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.quality-icon {
  border-bottom: 1px solid transparent;

  &__image {
    aspect-ratio: 1;
    width: var(--quality-icon-size);
    height: var(--quality-icon-size);
    pointer-events: none;
    user-select: none;
  }

  &__concentration-icon {
    position: absolute;
    right: -8px;
    bottom: -5px;
    color: rgb(var(--gold-dark-2));
  }

  &__concentration-number {
    display: flex;
    position: absolute;
    right: 0;
    bottom: -7px;
    left: 0;
    color: rgb(var(--gold));
    font-size: 12px;
    font-weight: 700;
    line-height: 1em;
    place-content: center;
  }

  &__concentration-percent {
    align-self: flex-end;
    padding-left: 1px;
    color: rgb(var(--gold-dark-1));
    font-size: 10px;
    line-height: 1em;
  }

  &--concentration-not-offered {
    display: grid;
    position: relative;
    width: 40px;
    height: 40px;
    border-image: none;
    border: 1px solid rgb(var(--blue-dark-1) / 0.25);
    border-radius: 6px;
    place-content: center;
    filter: grayscale(1);

    .quality-icon__concentration-icon {
      position: relative;
      right: unset;
      bottom: unset;
      width: 22px;
      height: 22px;
      margin-top: 2px;
      margin-left: 3px;
      color: rgb(var(--blue-dark-1) / 0.6);
    }

    .x {
      display: flex;
      position: absolute;
      right: 0;
      bottom: -7px;
      left: 0;
      color: rgb(var(--blue));
      font-size: 12px;
      font-weight: 700;
      line-height: 1em;
      place-content: center;
    }
  }

  &--with-concentration {
    background-image: linear-gradient(to bottom, transparent 45%, rgb(var(--gold) / 0.1));
    border-image: linear-gradient(to right, rgb(var(--slate-dark-2)), rgb(var(--gold-dark-2)) 70%) 1;

    .quality-icon__image {
      opacity: 0.4;
    }
  }
}
</style>
