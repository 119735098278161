<template>
  <AppAlerts/>

  <transition name="fade">
    <header
      v-if="isAppReady"
      :class="['header', {
        'header--full-width': isContentFullWidth,
        'header--show-tutorial': showTutorial,
        'header--maintenance-mode': isMaintenanceMode,
      }]">
      <RouterLink
        class="header__logo"
        :disabled="isMaintenanceMode"
        :to="{ name: 'search' }">
        <img
          alt="ForgeFinder logo"
          class="app-logo"
          src="/logo.svg">
        <span class="app-name font-wow">ForgeFinder</span>
        <AppIcon
          v-if="isDevMode"
          :name="showDevThings ? 'star' : 'starOutline'"
          @click.prevent.stop="toggleDevBtns"/>
      </RouterLink>

      <nav class="header__nav nav">
        <RouterLink
          v-if="!isMaintenanceMode"
          :to="{ name: 'search' }"
          data-tooltip-b="Search">
          <AppIcon
            class="nav__icon"
            name="search"/>
          <span class="nav__text">Search</span>
        </RouterLink>

        <RouterLink
          v-if="isAuthenticated && !isMaintenanceMode"
          :to="{ name: 'listings' }"
          data-tooltip-b="Listings">
          <AppIcon
            class="nav__icon"
            name="list"/>
          <span class="nav__text">Listings</span>
        </RouterLink>

        <a
          :class="['releases', { 'releases--has-unseen-changes': hasUnseenReleaseChanges }]"
          data-tooltip-b="Releases"
          tabindex="0"
          @click="openReleasesModal"
          @keyup.enter="openReleasesModal">
          <AppIcon
            class="nav__icon"
            name="rocket"/>
          <span class="nav__text">Releases</span>
        </a>

        <RouterLink
          :class="[{ 'router-link-exact-active': ['help', 'privacy', 'terms', 'faq'].includes($route.name) }]"
          data-tooltip-b="Help"
          :to="{ name: 'help' }">
          <AppIcon
            class="nav__icon"
            name="shield"/>
          <span class="nav__text">Help</span>
        </RouterLink>

        <RouterLink
          data-tooltip-b="Support"
          :to="{ name: 'support' }">
          <AppIcon
            class="nav__icon"
            name="discord"/>
          <span class="nav__text">Support</span>
        </RouterLink>

        <AppShareBtn
          class="header__share-btn"
          data-tooltip-b="Share"
          :size="30"
          is-nav-item
          name="share"
          text="Share"/>

        <template v-if="showDevLinks && showDevThings">
          <RouterLink
            class="dev-link"
            data-tooltip-b="Sandbox"
            :to="{ name: 'sandbox' }">
            <span class="nav__text">Sandbox</span>
          </RouterLink>

          <RouterLink
            class="dev-link"
            data-tooltip-b="Admin"
            :to="{ name: 'admin' }">
            <span class="nav__text">Admin</span>
          </RouterLink>
        </template>

        <DonateBtn platform="patreon"/>

        <AuthButton
          v-if="!isMaintenanceMode"
          data-guide-logged-in-benefits-step-4
          data-guide-logged-in-benefits-step-4-highlight
          class="nav__auth-btn"/>
      </nav>

      <OnClickOutside
        class="nav-menu__wrapper"
        @trigger="closeOptions">
        <details
          ref="navMenu"
          class="nav-menu">
          <summary class="nav-menu__burger-icon hamburger-menu-icon">
            <span/>
            <span/>
            <span/>
            <span/>
          </summary>

          <div class="nav-menu__options">
            <RouterLink
              v-if="!isMaintenanceMode"
              class="option"
              :to="{ name: 'search' }"
              @click.stop="closeOptions">
              <AppIcon
                class="nav-menu__icon"
                name="search"/>
              <span class="nav__text">Search</span>
            </RouterLink>

            <RouterLink
              v-if="!isMaintenanceMode && isAuthenticated"
              class="option option--listings"
              :to="{ name: 'listings' }"
              @click.stop="closeOptions">
              <AppIcon name="list"/>Listings
              <span
                v-if="isOnHoliday"
                class="holiday-mode">On Holiday</span>
            </RouterLink>

            <a
              :class="['releases option', { 'releases--has-unseen-changes': hasUnseenReleaseChanges }]"
              @click.stop="openReleasesModal">
              <AppIcon
                class="nav__icon"
                name="rocket"/>
              <span class="nav__text">Releases</span>
            </a>

            <RouterLink
              class="option"
              :to="{ name: 'help' }"
              @click.stop="closeOptions">
              <AppIcon
                class="nav-menu__icon"
                name="shield"/>
              <span class="nav-menu__text">Help</span>
            </RouterLink>

            <RouterLink
              class="option"
              :to="{ name: 'support' }"
              @click.stop="closeOptions">
              <AppIcon
                class="nav-menu__icon"
                name="discord"/>
              <span class="nav-menu__text">Support</span>
            </RouterLink>

            <AppShareBtn
              class="option option--share"
              :size="30"
              name="share"
              text="Share"/>

            <template v-if="showDevLinks && showDevThings">
              <RouterLink
                class="dev-link option--section-top"
                :to="{ name: 'sandbox' }"
                @click.stop="closeOptions">
                <span class="nav-menu__text">Sandbox</span>
              </RouterLink>

              <RouterLink
                class="dev-link"
                :to="{ name: 'admin' }">
                <span class="nav-menu__text">Admin</span>
              </RouterLink>
            </template>

            <template v-if="!isMaintenanceMode">
              <template v-if="isAuthenticated">
                <RouterLink
                  class="option option--section-top"
                  :to="{ name: 'account' }"
                  @click.stop="closeOptions">
                  <AppIcon name="account"/>My Account
                </RouterLink>

                <RouterLink
                  class="option"
                  :to="{ name: 'logout' }"
                  @click.stop="closeOptions">
                  <AppIcon name="logout"/>Logout
                </RouterLink>
              </template>

              <template v-else>
                <span
                  class="option option--section-top"
                  @click="login">
                  <AppIcon name="login"/>Login
                </span>
              </template>
            </template>

            <DonateBtn
              platform="patreon"
              expanded/>
            <DonateBtn
              platform="kofi"
              expanded/>
          </div>
        </details>
      </OnClickOutside>
    </header>
  </transition>

  <AppModal
    v-model="showReleasesModal"
    title="Release Notes"
    :width="920"
    close-on-esc
    close-on-bg-click
    @update:model-value="(value) => !value && closeReleasesModal()">
    <div class="changelog">
      <Changelog/>
    </div>
  </AppModal>

  <RouterView
    :class="['main', {
      'main--full-width': isContentFullWidth,
      'main--hide-help-menu': showReleasesModal,
    }]"
    v-slot="{ Component }">
    <AppMessage
      v-if="showMobileMessage && isAppReady && !isMaintenanceMode"
      class="mobile-warning"
      type="warning"
      content="This site is best viewed on desktop resolutions"
      can-close
      @closed="showMobileMessage = false"/>
    <transition name="fade">
      <component :is="Component"/>
    </transition>
  </RouterView>
</template>

<script setup>
import AppAlerts from '@/components/AppAlerts.vue'
import AuthButton from '@/components/AuthButton.vue'
import AppShareBtn from '@/components/AppShareBtn.vue'
import Changelog from '@/PUBLIC_CHANGELOG.md'
import { generateNonce } from '@/utils/api/battlenet/config'
import { ref, onBeforeMount, computed } from 'vue'
import { RouterLink, RouterView, useRoute, useRouter } from 'vue-router'
import { useUserStore, useSearchStore, useWowStore, useDevStore, useAlertsStore } from '@/stores'
import { OnClickOutside } from '@vueuse/components'
import { supabase } from './utils/api/db/config'

const route = useRoute()
const router = useRouter()

const alerts = useAlertsStore()
const dev = useDevStore()
const search = useSearchStore()
const user = useUserStore()
const wow = useWowStore()

const isAuthenticated = computed(() => user.isAuthenticated)
const isContentFullWidth = computed(() => ['listings'].includes(route.name))
const isDevMode = computed(() => dev.isDevMode)
const isMaintenanceMode = computed(() => dev.isMaintenanceMode)
const isOnHoliday = computed(() => user.isOnHoliday)
const showDevLinks = computed(() => _.every([dev.isDevMode, dev.showDevThings]))
const showDevThings = computed(() => dev.showDevThings)
const showTutorial = computed(() => search.isTourActive.qualitiesAndReagents && route.name === 'search')

const hasUnseenReleaseChanges = ref(false)
const isAppReady = computed(() => _.isBoolean(dev.isMaintenanceMode))
const navMenu = ref()
const showMobileMessage = ref(true)
const showReleasesModal = ref(false)

supabase()
  .channel('table-db-changes')
  .on(
    'postgres_changes',
    {
      event: 'UPDATE',
      schema: 'public',
      table: 'site_settings',
    },
    (payload) => {
      if (payload.new.key !== 'is_site_readonly') return

      const isInMaintenanceMode = payload.new.value === 'true'

      dev.setMaintenanceMode(isInMaintenanceMode)

      if (isInMaintenanceMode) {
        !route.meta.canAccessDuringMaintenance && router.push({ name: 'maintenance' })
        return
      }

      if (route.name === 'maintenance') router.replace({ name: 'search' })
      setupApp()
      alerts.SHOW_ALERT({
        type: 'success',
        title: 'Maintenance complete',
        content: 'You may now use the site as normal',
      })
    },
  )
  .subscribe()

onBeforeMount(async () => {
  const favicon = document.querySelector('[data-js="favicon"]')
  const replaceFavicon = (mode) => favicon.href = favicon.href.replace('/favicon.png', `/favicon-${mode}.png`)

  if (location.origin.includes('localhost')) replaceFavicon('local')
  if (location.origin.includes('deploy-preview')) replaceFavicon('pr')

  hasUnseenReleaseChanges.value = localStorage.getItem('wff:lastSeenVersion') !== CURRENT_VERSION

  if (!isMaintenanceMode.value) await setupApp()
})

function login () {
  closeOptions()
  const { name, query, hash, path } = route
  localStorage.setItem('redirectRoute', JSON.stringify({ name, query, hash, path }))
  window.location.replace(`/.netlify/functions/auth?state=${generateNonce()}`)
}

async function setupApp () {
  await wow.GET_REALMS()
  await wow.GET_RECIPES()
  if (user.isAuthenticated) {
    await user.GET_HOLIDAY_MODE()
    await user.GET_PROFILE()
  }
}

function toggleDevBtns () {
  dev.toggleShowDevThings()
}

function closeOptions () {
  navMenu.value.removeAttribute('open')
}

function openReleasesModal () {
  closeOptions()
  showReleasesModal.value = true
  localStorage.setItem('wff:lastSeenVersion', CURRENT_VERSION)
}

function closeReleasesModal () {
  hasUnseenReleaseChanges.value = false
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  position: sticky;
  z-index: 10;
  top: 0;
  flex: 0 1 auto;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 1200px;
  padding: 60px 0 30px;
  transition:
    padding 0.35s ease-in,
    width 0.35s ease-in;
  background: rgb(var(--background));

  &__logo {
    display: flex;
    align-items: center;

    .app-logo {
      width: 40px;
      height: 40px;
    }

    .app-name {
      padding-left: 0.25em;
      color: rgb(var(--gold));
      font-size: 24px;
    }
  }

  &__nav {
    display: flex;
    position: relative;
    align-items: center;
  }

  &__share-btn.share-btn {
    background: none;
    color: rgb(var(--gold));

    :deep(.icon) {
      color: rgb(var(--gold));
    }

    &:hover,
    &:focus {
      background: none;

      :deep(.icon) {
        color: rgb(var(--gold-light-1));
      }
    }
  }

  &--full-width {
    width: 100%;
    padding: 60px 30px 30px;
  }

  &--show-tutorial {
    padding-top: 30px;
    padding-bottom: 224px;
  }

  &--maintenance-mode {
    .header__nav {
      margin-right: 48px;

      :deep(.donate-link) {
        position: absolute;
        right: -48px;
      }
    }
  }
}

.main {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-width: 1200px;
  margin: 30px auto 60px;
  overflow: auto;

  &--full-width {
    min-width: 100vw;
    padding: 0 30px;
  }

  &--hide-help-menu {
    :deep(.help-menu) {
      display: none;
    }
  }
}

.nav {
  gap: 1em;

  &__auth-btn {
    margin-left: 40px;
  }

  a {
    display: flex;
    gap: 4px;
    align-items: center;

    &:first-of-type {
      border: 0;
    }

    &.router-link-exact-active {
      color: rgb(var(--text));

      &:hover {
        background-color: transparent;
      }
    }
  }

  :deep(.donate-link) {
    position: absolute;
    right: 240px;
  }

  &-menu {
    position: relative;
    cursor: pointer;

    &__burger-icon.hamburger-menu-icon {
      position: relative;
      width: 35px;
      height: 30px;
      padding-top: 10px;
      transform:
        rotate(0deg)
        scale(0.75);
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        border-radius: 3px;
        opacity: 1;
        background: rgb(var(--white));
      }

      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2),
      span:nth-child(3) {
        top: 12px;
      }

      span:nth-child(4) {
        top: 24px;
      }
    }

    &[open] .hamburger-menu-icon {
      span:nth-child(1) {
        top: 5px;
        left: 50%;
        width: 0%;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 5px;
        left: 50%;
        width: 0%;
      }
    }

    &__wrapper {
      display: none;
    }

    .option,
    .dev-link {
      display: flex;
      align-items: center;
      padding: 0.5em 1em;
      gap: 6px;
    }

    .option {
      justify-content: flex-start;
      transition: background 0.35s ease-in;
      border-radius: 0;
      background: none !important;
      color: rgb(var(--text));
      white-space: pre;

      &--section-top {
        border-top: 1px solid rgb(var(--grey-dark-1));
      }

      &:hover,
      &:focus {
        background: rgb(var(--black) / 0.25) !important;
        color: rgb(var(--white));
      }
    }

    :deep(.donate-link) {
      align-self: center;
    }

    .holiday-mode {
      color: rgb(var(--grey));
    }

    &__options {
      display: flex;
      position: absolute;
      top: 30px;
      right: 0;
      flex-direction: column;
      min-width: 200px;
      padding: 6px 0 12px;
      gap: 6px;
      background: rgb(var(--grey-dark-3));
      box-shadow: 0 4px 8px rgb(var(--black) / 0.35);
    }
  }

  a[data-tooltip-b]:hover::after {
    display: none;
  }
}

.releases {
  position: relative;

  &--has-unseen-changes::before {
    content: "";
    display: inline-block;
    position: absolute;
    right: -8px;
    bottom: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgb(var(--green));

    .nav-menu & {
      right: 89px;
      bottom: 10px;
    }
  }
}

.mobile-warning {
  display: none !important;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active,
:deep(.fade-enter-active),
:deep(.fade-leave-active) {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to,
:deep(.fade-enter-from),
:deep(.fade-leave-to) {
  transition: none;
  opacity: 0;
}

@media (width <= 1380px) {
  .header {
    padding: 60px 0 30px;

    &--full-width {
      padding: 60px 30px 30px;
    }

    &--show-tutorial {
      padding-top: 30px;
      padding-bottom: 224px;
    }
  }

  .main {
    margin: 30px auto 60px;

    &--full-width {
      min-width: 100vw;
    }
  }
}

@media (width <= 1260px) {
  .header,
  .main {
    width: 100vw;
    min-width: unset;
    padding-right: 30px;
    padding-left: 30px;
  }

  .header {
    padding-top: 60px;
    padding-bottom: 30px;

    &--show-tutorial {
      padding-top: 30px;
      padding-bottom: 224px;
    }
  }
}

@media (width <= 1050px) {
  .header:not(.header--maintenance-mode) {
    .nav {
      gap: 8px;

      a,
      .share-btn {
        padding: 0;

        &:not(.router-link-exact-active) .nav__text,
        :deep(.share-btn__text) {
          display: none;
        }

        &.releases--has-unseen-changes::before {
          right: -2px;
        }
      }

      a:not(.router-link-exact-active)[data-tooltip-b]:hover::after {
        display: flex;
      }
    }
  }

  .main {
    margin: 0 auto 30px;
  }
}

@media (width <= 900px) {
  .header {
    padding-top: 30px;

    &--show-tutorial {
      padding-top: 30px;
      padding-bottom: 224px;
    }
  }

  .main {
    width: calc(100% - 60px);
    min-width: calc(100% - 60px);
  }
}

@media (width <= 750px) {
  .alert.mobile-warning {
    display: flex !important;
    flex: 0 1 auto;
    place-self: center center;
    width: auto;
    max-width: 100%;
    margin: 0 30px 30px !important;
    padding: 4px 8px;
    font-size: 14px;
  }

  .nav {
    display: none;

    &__auth-btn {
      display: none;
    }

    &-menu__wrapper {
      display: inline-block;
    }
  }
}

@media (width <= 500px) {
  .header {
    padding: 20px 14px;
  }

  .main {
    width: 100%;
    margin: 0;
    padding: 0 14px 14px;
  }

  .alert.mobile-warning {
    margin: 0 20px 20px !important;
    font-size: 12px;
  }
}

@media (width <= 300px) {
  .alert.mobile-warning {
    font-size: 10px;
  }
}
</style>
