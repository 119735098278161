import { defineStore } from 'pinia'
import Alerts from './alerts'
import Dev from './dev'
import Search from './search'
import User from './user'
import UserListings from './userListings'
import Wow from './wow'

export const useAlertsStore = defineStore('alerts', Alerts)
export const useDevStore = defineStore('dev', Dev)
export const useSearchStore = defineStore('search', Search)
export const useUserListingsStore = defineStore('userListings', UserListings)
export const useUserStore = defineStore('user', User)
export const useWowStore = defineStore('wow', Wow)
