import _ from 'lodash' // eslint-disable-line no-restricted-imports

export const lodashMixin = {
  $camelCaseKeys (object) {
    const camelCase = (object) => {
      return _.transform(object, (acc, value, key, target) => {
        const camelKey = _.isArray(target) ? key : _.camelCase(key)

        acc[camelKey] = _.isObject(value) ? camelCase(value) : value
      })
    }

    return camelCase(object)
  },
  /** Flattens an Object to replace each key/hierarchy with a singular flat path
   *
   * @returns Object with keys as paths
   *    @example
   *      _.$flattenObject({ a: { b: {c: 1, d: [3] } } })
   *      // returns { 'a.b.c': 1, 'a.b.d[0]' = 3 }
   */
  $flattenObject (obj = {}) {
    const result = {}

    const flatten = (collection, prefix = '', suffix = '') => {
      _.forEach(collection, (value, key) => {
        const path = `${prefix}${key}${suffix}`

        if (_.isArray(value)) flatten(value, `${path}[`, ']')
        else if (_.isPlainObject(value)) flatten(value, `${path}.`)
        else result[path] = value
      })
    }

    flatten(obj)
    return result
  },
  /** Returns the `obj[path]` if the value is truthy
   * Else returns the `alternativeValue`
   */
  $get (obj, path, alternativeValue) {
    return _.get(obj, path) || alternativeValue
  },
  /** Finds the first populated value, given an Object and a list of valid keys to check
   *
   * @example
   *   const obj = { a: '', b: 2, c: [1, 2, 3], d: 'apple' }
   *   _.$getFirstPopulatedValue(obj, ['a', 'b', 'd'])
   *   // returns 2
   *   _.$getFirstPopulatedValue(obj, ['a', 'd'])
   *   // returns 'apple'
   *   _.$getFirstPopulatedValue(obj, ['a', 'e'])
   *   // returns null
   */
  $getFirstPopulatedValue (obj, validKeys) {
    const sortedValues = _.map(validKeys, (key) => obj[key])
    return _.find(sortedValues) || null
  },
  $getListFromArray (theArray, { conjunction = '&', boldItems = false } = {}) {
    const array = _.cloneDeep(theArray)
    const isValidArray = _.every(array, _.isString) && !_.isEmpty(array) && _.isArray(array)
    if (!isValidArray) {
      console.warn('[Lodash mixin $getListFromArray] was not passed a valid param. The param must be an Array with all of its items as Strings', array)
      return ''
    }
    const finalItem = array.pop()
    const wrapItem = (item) => boldItems ? `<b>${item}</b>` : item
    return array.length
      ? `${array.map((item) => wrapItem(item)).join(', ')} ${conjunction} ${wrapItem(finalItem)}`
      : `${wrapItem(finalItem)}`
  },
  $hasAll (obj, keysToCheck) {
    if (_.$typeOf(obj) !== 'object') console.warn(`[Lodash mixin $hasAll] was not passed a valid 1st param, it must be an Object`, obj)
    if (!_.isArray(keysToCheck)) console.warn(`[Lodash mixin $hasAll] was not passed a valid 2nd param, it must be an Array`, keysToCheck)
    if (_.$typeOf(obj) !== 'object' || !_.isArray(keysToCheck)) return false
    return keysToCheck.every((key) => _.has(obj, key))
  },
  $hasAny (obj, keysToCheck) {
    if (_.$typeOf(obj) !== 'object') console.warn(`[Lodash mixin $hasAny] was not passed a valid 1st param, it must be an Object`, obj)
    if (!_.isArray(keysToCheck)) {
      console.warn(`[Lodash mixin $hasAny] was not passed a valid 2nd param, it must be an Array`, keysToCheck)
      return false
    }
    return keysToCheck.some((key) => _.has(obj, key))
  },
  $isEmpty (value) {
    const canBeEmptyChecked = ['object', 'array', 'string', 'null', 'undefined']
    return canBeEmptyChecked.includes(_.$typeOf(value)) && _.isEmpty(value)
  },
  /** Returns true if not an Array, Object, null nor undefined
   * https://lodash.com/docs/#isObject
   */
  $isPrimitive (value) {
    return !_.isObject(value) && !_.isNil(value)
  },
  $pascalCase (string) {
    return _.chain(string).camelCase().upperFirst().value()
  },
  /** Pauses code for x milliseconds */
  $pause (milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  },
  $randomFromList (array = []) {
    if (!_.isArray(array) || _.isEmpty(array)) return console.warn(`[Lodash mixin $randomFromList] was not passed a valid param, it must be an Array`, array)
    return array[_.random(array.length - 1)]
  },
  $snakeCaseKeys (object) {
    return _.mapKeys(object, (_value, key) => _.snakeCase(key))
  },
  $sortObjKeys (object) {
    return _(object).toPairs().sortBy(0).fromPairs().value()
  },
  $typeOf (value) {
    return ({}).toString.call(value).match(/\s([A-Za-z]+)/)[1].toLowerCase()
  },
}

_.mixin(lodashMixin)
window._ = _

export const lodashPlugin = {
  // https://vuejs.org/api/application.html#app-provide
  // https://vuejs.org/guide/components/provide-inject.html#app-level-provide
  // To use in component templates just add this line:
  // inject: ['$_'],
  //
  // Then you can access it like this:
  // {{ $_.sample([1, 2, 3]) }}
  install (app) {
    app.provide('$_', _)
  },
}

export default _
