<template>
  <dialog
    :open="modelValue"
    :style="`--modal-width: ${width}px;`"
    class="app-modal__wrapper"
    tabindex="0"
    @keydown.esc="handleEscPress"
    @click="handleBgClick">
    <div
      ref="modalContent"
      tabindex="0"
      class="app-modal">
      <header class="app-modal__header">
        <h2 class="app-modal__title">
          {{ title }}
        </h2>
        <AppIcon
          class="app-modal__close"
          name="close"
          :size="30"
          tabindex="0"
          @keyup.enter="close"
          @click="close"/>
      </header>
      <div class="app-modal__content">
        <slot/>
      </div>
      <div
        v-if="$slots.actions"
        class="app-modal__actions">
        <slot name="actions"/>
      </div>
    </div>
  </dialog>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 600,
    },
    closeOnBgClick: {
      type: Boolean,
      default: false,
    },
    closeOnEsc: {
      type: Boolean,
      default: false,
    },
  },

  emits: { 'update:model-value': _.isBoolean },

  watch: {
    modelValue (newVal) {
      if (newVal) this.$refs.modalContent.focus()
    },
  },

  methods: {
    close () {
      this.$emit('update:model-value', false)
    },

    handleBgClick ({ target }) {
      const isValid = target?.className?.includes?.('app-modal__wrapper')
      if (this.closeOnBgClick && isValid) this.close()
    },

    handleEscPress () {
      if (this.closeOnEsc) this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  width: var(--modal-width);
  max-width: 100%;
  max-height: 100%;
  padding: 30px;
  border-radius: 8px;
  background: rgb(var(--slate));

  &__header {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    padding: 10px 0;
    color: rgb(var(--gold));
  }

  &__title {
    font-size: 40px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    transition: color 0.25s ease-in;
    color: rgb(var(--grey-light-1));
    cursor: pointer;

    &:hover,
    &:active {
      color: rgb(var(--white));
    }
  }

  &__content {
    flex: 1 1 auto;
    margin-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
    color: rgb(var(--text));

    :deep(p:not(:first-child)) {
      margin-top: 16px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    margin-top: 16px;
  }

  &__wrapper {
    display: flex;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
    background: rgb(var(--black) / 0.5);

    @include get-dvw(100);
    @include get-dvh(100);

    &[open] {
      z-index: 999;
      transition: opacity 0.25s ease-in-out;
      opacity: 1;
    }
  }
}

@media (width <= 600px) {
  .app-modal {
    padding: 14px;

    :deep(ul) {
      padding-left: 24px;
    }

    :deep(h2) {
      padding-left: 0;
    }

    &__wrapper {
      padding: 6px;
    }

    &__close {
      right: 12px;

      --icon-size: 40px !important;
    }
  }
}
</style>
