import { createApp } from 'vue'
import { createPinia } from 'pinia'

import '@/utils/plugins/lodash'
import App from './App.vue'
import router from './router'
import registerGlobalComponents from '@/utils/global/components'
import registerGlobalPlugins from '@/utils/global/plugins'
import errorHandler from '@/utils/errors/handler'

import '@globalhive/vuejs-tour/dist/style.css'
import './assets/scss/index.scss'
const app = createApp(App)

app.config.errorHandler = errorHandler

app.use(createPinia())
app.use(router)
registerGlobalComponents(app)
registerGlobalPlugins(app)

app.mount('#app')
