import { callApi } from '../config'

const regions = import.meta.env.VITE_BATTLENET_REGIONS.split(',')

const URI = regions.reduce((acc, region) => ({
  ...acc,
  [region]: `https://${region}.api.blizzard.com`,
}), {})

// https://develop.battle.net/documentation/world-of-warcraft/game-data-apis
export default {
  /* eslint sort-keys: "error" */
  // Profession API :: GET Professions Index
  async getAllProfessions ({ region, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/profession/index`,
      query: { namespace: `static-${region}` },
      retries,
    })
  },

  // Connected Realm API :: GET Connected Realms Index
  async getConnectedRealms ({ region, retries = 2 } = {}) {
    const { data } = await callApi({
      path: `${URI[region]}/data/wow/connected-realm/index`,
      query: { namespace: `dynamic-${region}` },
      retries,
    })

    const connectedRealms = {}
    for (const connectedRealmsData of data.connectedRealms) {
      const connectedRealmsId = parseInt(connectedRealmsData.href.match('data/wow/connected-realm/([0-9]+)')[1])

      const { data: connectedRealmData } = await callApi({
        path: `${URI[region]}/data/wow/connected-realm/${connectedRealmsId}`,
        query: { namespace: `dynamic-${region}` },
        retries,
      })

      for (const realm of connectedRealmData.realms) {
        if (!connectedRealms[connectedRealmsId]) connectedRealms[connectedRealmsId] = []
        connectedRealms[connectedRealmsId].push(realm.id)
      }
    }

    return { data: connectedRealms }
  },

  // Item API :: GET Item
  async getItem ({ region, itemId, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/item/${itemId}`,
      query: { namespace: `static-${region}` },
      retries,
    })
  },

  // Item API :: GET Item Media
  async getItemIcon ({ region = 'eu', itemId } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/media/item/${itemId}`,
      query: { namespace: `static-${region}` },
    })
  },

  // Profession API :: GET Profession
  async getProfession ({ region, profId, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/profession/${profId}`,
      query: { namespace: `static-${region}` },
      retries,
    })
  },

  // Profession API :: GET Professions Index
  async getProfessionRecipes ({ region, profId, tierId, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/profession/${profId}/skill-tier/${tierId}`,
      query: { namespace: `static-${region}` },
      retries,
    })
  },

  // Modified Crafting API :: GET Modified Crafting Reagent Slot Type
  async getReagentSlotOptions ({ region, reagentSlotId, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/modified-crafting/reagent-slot-type/${reagentSlotId}`,
      query: { namespace: `static-${region}` },
      retries,
    })
  },

  // Realm API :: GET Realms Index
  async getRealms ({ region, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/realm/index`,
      query: { namespace: `dynamic-${region}` },
      retries,
    })
  },

  // Profession API :: GET Recipe
  async getRecipe ({ region, recipeId, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/recipe/${recipeId}`,
      query: { namespace: `static-${region}` },
      retries,
    })
  },

  // Profession API :: GET Recipe Media
  async getRecipeIcon ({ region = 'eu', id } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/media/recipe/${id}`,
      query: { namespace: `static-${region}` },
    })
  },

  // Item API :: GET Item Search
  async searchItems ({ region, name, pageSize = 1, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/search/item`,
      query: { _pageSize: pageSize, 'name.en_US': name, namespace: `static-${region}` },
      retries,
    })
  },

  // Item API :: GET Item Search
  async searchItemsByCategoryId ({ region, categoryId, pageSize = 1, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/search/item`,
      query: { _pageSize: pageSize, 'modified_crafting.category.id': categoryId, namespace: `static-${region}` },
      retries,
    })
  },

  // Item API :: GET Item Search
  async searchItemsByCategoryName ({ region, name, pageSize = 1, retries = 2 } = {}) {
    return await callApi({
      path: `${URI[region]}/data/wow/search/item`,
      query: { _pageSize: pageSize, 'modified_crafting.category.name.en_US': name, namespace: `static-${region}` },
      retries,
    })
  },
}
