<template>
  <div class="stats">
    <div class="stats__block stats__block--users">
      <span class="stats__title">Crafter accounts</span>
      <span class="stats__count">{{ users }}+</span>
    </div>
    <div class="stats__block stats__block--listings">
      <span class="stats__title">Active listings</span>
      <span class="stats__count">{{ activeListings }}+</span>
    </div>
    <div class="stats__block stats__block--eu">
      <span class="stats__title">EU Realms</span>
      <span class="stats__count">{{ euRealms }}</span>
    </div>
    <div class="stats__block stats__block--us">
      <span class="stats__title">US Realms</span>
      <span class="stats__count">{{ usRealms }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Number,
      required: true,
    },
    activeListings: {
      type: Number,
      required: true,
    },
    euRealms: {
      type: Number,
      required: true,
    },
    usRealms: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.stats {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 8px;
  justify-content: start;
  padding-top: 10px;
  line-height: 1em;

  &__block {
    display: inline-flex;
    padding: 10px;
    gap: 4px;
    border-radius: 8px;
    background: rgb(var(--blue-dark-1) / 0.25);
    color: rgb(var(--blue));
    font-weight: 600;
  }

  &__title {
    color: rgb(var(--blue-light-1));
  }
}

@media (width <= 600px) {
  .stats {
    grid-template-areas:
      ". eu"
      ". us";
    grid-template-columns: repeat(2, auto);

    &__block--eu {
      grid-area: eu;
    }

    &__block--us {
      grid-area: us;
    }
  }
}

@media (width <= 300px) {
  .stats {
    display: flex;
    flex-direction: column;

    &__block {
      justify-content: space-between;
      font-size: 12px;
    }
  }
}
</style>
