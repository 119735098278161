import api from '@/utils/api'
import { useDevStore, useUserStore } from '@/stores/index'
import { getQuality } from '@/utils/helpers/qualityCalculator'
import { getSortOptions } from '@/utils/config/filters'
import { isToday, isYesterday, parseISO } from 'date-fns'
import dummyListings from '@/utils/mockData/dummyListings'

const filtersDefaults = {
  expansionKey: 'tww',
  listing: {},
  maxCost: 9999999,
  minQuality: 1,
  optionalReagentsIndex: {},
  reagentsProvidedIndex: 0,
  realm: {},
  recipe: {},
  showOnlyFavourites: false,
  showOnlyOnline: false,
  showOnlyReagentsProvided: false,
  showProvidedReagents: false,
  sortBy: 1,
  tierIndex: 2,
}

export default {
  state: () => ({
    dummyListingIndex: null,
    filters: _.cloneDeep(filtersDefaults),
    isTourActive: {
      qualitiesAndReagents: false,
      loggedInBenefits: false,
    },
    listings: {},
    fetchingListings: false,
  }),

  getters: {
    showDummyListing: (state) => !_.isNull(state.dummyListingIndex),

    canRecraft: (state) => {
      const { recipe } = state.filters
      return ['Weapon', 'Armor', 'Profession'].includes(recipe?.items?.[0]?.type)
    },

    recipeRequiredReagents (state) {
      return _(state.filters.recipe.recipeReagents)
        .filter((modifiedReagent) => !modifiedReagent.crafterOnly)
        .value()
    },

    recipeRequiredModifiedReagents: (state) => _(state.filters.recipe.recipeModifiedReagents)
      .filter((modifiedReagent) => !modifiedReagent.crafterOnly && !modifiedReagent.optional)
      .orderBy(['order'])
      .value(),

    recipeOptionalModifiedReagents: (state) => _(state.filters.recipe.recipeModifiedReagents)
      .filter((modifiedReagent) => !modifiedReagent.crafterOnly && modifiedReagent.optional)
      .orderBy(['order'])
      .value(),

    canProvideReagents: (state) => _.some([
      state.filters.listing?.canProvideNonTier,
      state.filters.listing?.canProvideTier1,
      state.filters.listing?.canProvideTier2,
      state.filters.listing?.canProvideTier3,
    ]),

    getRecipePricesForListing: (state) => (listing) => {
      const { filters } = state

      const selectedTier = filters.tierIndex + 1

      const providedNonTierPrice = listing?.nonTierPrice || 0
      const providedTieredPrice = listing?.[`tier${selectedTier}Price`] || 0
      const providedReagentsPrice = _.sum([
        listing?.canProvideNonTier && filters.showProvidedReagents
          ? providedNonTierPrice
          : 0,
        listing?.[`canProvideTier${selectedTier}`] && filters.showProvidedReagents
          ? providedTieredPrice
          : 0,
      ])

      const priceWithoutConcentration = listing.basePrice + providedReagentsPrice
      const priceWithConcentration = listing.freeConcentration
        ? priceWithoutConcentration
        : _.isNil(listing.baseConcentrationPrice)
          ? null
          : listing.baseConcentrationPrice + providedReagentsPrice

      return {
        withoutConcentration: {
          forTips: priceWithoutConcentration === 0,
          price: priceWithoutConcentration,
        },
        withConcentration: {
          forTips: priceWithConcentration === 0,
          price: priceWithConcentration,
        },
      }
    },

    hasAnyListings: ({ listings, filters }) => {
      const realmSlug = `${filters.realm.region}-${filters.realm.slug}`
      const realmSpecificListings = listings[realmSlug] || {}
      return !_.isEmpty(realmSpecificListings[filters.recipe.id])
    },

    filteredListings: ({ showDummyListing, dummyListingIndex, listings, filters }) => {
      const realmSlug = `${filters.realm.region}-${filters.realm.slug}`
      const realmSpecificListings = listings[realmSlug] || {}
      const recipeSpecificListings = realmSpecificListings[filters.recipe.id] || {}

      const user = useUserStore()

      const filteredListings = _.filter(recipeSpecificListings, ({
        isActive,
        character,
        basePrice,
        skill,
        multicraftPercent,
        canProvideNonTier,
        canProvideTier1,
        canProvideTier2,
        canProvideTier3,
        freeConcentration,
        baseConcentrationPrice,
      }) => {
        if (!isActive) return false

        const { recipe, showOnlyOnline, showOnlyReagentsProvided, showOnlyFavourites, maxCost } = filters
        const isFavourite = _.some(user.favourites.crafters, ({ characterId }) => character?.id === characterId)
        const qualityWithoutConcentration = getQuality({ skill, filters, recipe })
        const canConcentrate = !!(freeConcentration || !_.isNil(baseConcentrationPrice))

        if (_.some([
          skill > recipe.maxSkill,
          multicraftPercent > recipe.maxMulticraftPercent,
          character?.errorStartedAt !== null,
          showOnlyOnline && !isToday(parseISO(character.lastOnlineAt) && !isYesterday(parseISO(character.lastOnlineAt))),
          showOnlyReagentsProvided && !_.some([
            canProvideNonTier,
            canProvideTier1,
            canProvideTier2,
            canProvideTier3,
          ]),
          showOnlyFavourites && !isFavourite,
          maxCost > 0 && parseInt(basePrice) > parseInt(maxCost),
        ])) return false

        return !filters.minQuality ||
          Math.min(qualityWithoutConcentration + canConcentrate, recipe.maxSkill) >= filters.minQuality
      })

      const sortOptions = getSortOptions()
      const sortBy = sortOptions[filters.sortBy]

      const searches = []

      for (const sortByField of sortBy.field) {
        searches.push((listing) => {
          const data = {
            price: listing.basePrice ?? 0,
            name: listing.character?.name,
            quality: listing.skill ?? 0,
            multicraftPercent: listing.multicraftPercent ?? 0,
          }

          return data[sortByField] ?? listing.id
        })
      }

      const sortedListings = _.orderBy(filteredListings, searches, sortBy.direction)
      return showDummyListing
        ? [dummyListings(filters.realm, dummyListingIndex), ...sortedListings]
        : sortedListings
    },
  },

  actions: {
    async GET_LISTINGS ({ realm, recipe }) {
      this.fetchingListings = true
      const realmSlug = `${realm.region}-${realm.slug}`
      const recipeId = recipe.id
      const { data } = await api.db.getListings({ connectedRealmId: realm.connectedRealmId, recipeId })
      const fakeData = useDevStore().showDevThings ? await api.db.getFakeListings() : []

      if (!this.listings[realmSlug]) this.listings[realmSlug] = {}
      if (!this.listings[realmSlug][recipeId]) this.listings[realmSlug][recipeId] = {}
      this.listings[realmSlug][recipeId] = _.map([data, fakeData].flat(), (listing) => ({
        ...listing,
        providedReagentIds: _.map(listing.providedReagentIds, 'reagentId'),
      }))
      this.fetchingListings = false
    },

    setListing ({ listing }) {
      this.filters.listing = listing
    },

    clearListing () {
      this.filters.listing = filtersDefaults.listing
    },

    resetFilters (acceptedKeys = []) {
      for (const key in this.filters) {
        if (acceptedKeys.length > 0 && !acceptedKeys.includes(key)) continue
        this.filters[key] = filtersDefaults[key]
      }
    },

    setFilter (key, newValue) {
      this.filters[key] = newValue
    },

    setFilters (newFilters) {
      for (const key in newFilters) {
        if (!this.filters?.[key]) continue
        this.filters[key] = newFilters[key]
      }
    },

    toggleDummyListing (newValue = !this.showDummyListing, index = null) {
      if (!newValue) {
        this.dummyListingIndex = null
        this.resetFilters(['listing'])
        return
      }
      this.dummyListingIndex = index
      this.setListing({ listing: this.filteredListings[0] })
    },

    setTourActive (newVal, newKey) {
      for (const key in this.isTourActive) this.isTourActive[key] = false
      if (newVal) this.isTourActive[newKey] = true
    },
  },
}
