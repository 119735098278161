import deleteQueries from './queries/delete'
import insertQueries from './queries/insert'
import rpcQueries from './queries/rpc'
import selectQueries from './queries/select'
import updateQueries from './queries/update'
import upsertQueries from './queries/upsert'

export default {
  ...deleteQueries,
  ...insertQueries,
  ...rpcQueries,
  ...selectQueries,
  ...updateQueries,
  ...upsertQueries,
}
