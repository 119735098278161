import api from '@/utils/api'
import { getExpansionFromRecipe } from '@/utils/config/professions'

export default {
  state: () => ({
    items: [],
    reagents: [],
    realms: {
      eu: [],
      us: [],
    },
    recipes: [],
    recipesWithDetails: [],
  }),

  getters: {
    allRealms: ({ realms }) => [].concat(...Object.values(realms)),
    getRealmById: ({ allRealms }) => (realmId) => _.find(allRealms, ['id', realmId]),
    getRecipeById: ({ recipes }) => (recipeId) => _.find(recipes, ['id', recipeId]),

    regionNames: () => ({
      eu: 'Europe',
      us: 'Americas',
    }),
  },

  actions: {
    async GET_ITEMS () {
      const { data } = await api.db.getItems()
      this.items = data
    },

    async GET_REAGENTS () {
      const { data } = await api.db.getReagents()
      this.reagents = data
    },

    async GET_REALMS () {
      const res = await api.db.getRealms()

      const { eu, us } = _.groupBy(res.data, 'region')
      this.realms.eu = _(eu)
        .sortBy('name')
        .map((realm) => _.merge(realm, { region: 'eu' }))
        .value()

      this.realms.us = _(us)
        .sortBy('name')
        .map((realm) => _.merge(realm, { region: 'us' }))
        .value()
    },

    async GET_RECIPE ({ id }) {
      const index = _.findIndex(this.recipesWithDetails, ['id', id])
      if (index !== -1) return this.recipesWithDetails[index]

      const { data } = await api.db.getRecipeAndRelations({ id })
      this.recipesWithDetails.push(data)
      return data
    },

    async GET_RECIPES () {
      const { data } = await api.db.getRecipes()
      this.recipes = _(data)
        .sortBy(['name'])
        .map((recipe) => ({
          ...recipe,
          expansion: getExpansionFromRecipe(recipe),
        }))
        .value()
    },
  },
}
