export const GROUP_SPARK = 256
export const GROUP_INFUSE_WITH_POWER = 284
export const GROUP_INFUSE_WITH_POWER_2 = 366
export const GROUP_SOCKET = 363
export const GROUP_SOCKET_2 = 364
// export const GROUP_EMPOWER = 342
// export const GROUP_EMPOWER = 368

export const ITEM_ENCHANTED_RUNED_HARBINGER_CREST = 224072
export const ITEM_ENCHANTED_GILDED_HARBINGER_CREST = 224073
export const ITEM_SPARK_OF_OMENS = 211296

export const ITEM_ENCHANTED_WEATHERED_HARBINGER_CREST = 224069
export const ITEM_SOUL_SIGIL_2 = 228339
