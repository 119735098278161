<template>
  <span
    :class="['icon', { 'icon--loading': loading }]"
    :style="`--icon-size: ${size}px;`">
    <component :is="icon"/>
  </span>
</template>

<script>
// blizzard
import alliance from '@/icons/alliance.svg?component'
import battlenet from '@/icons/battlenet.svg?component'
import horde from '@/icons/horde.svg?component'
import neutral from '@/icons/neutral.svg?component'
// regular
import account from '@/icons/account.svg?component'
import addListing from '@/icons/addListing.svg?component'
import caret from '@/icons/caret.svg?component'
import change from '@/icons/change.svg?component'
import chat from '@/icons/chat.svg?component'
import check from '@/icons/check.svg?component'
import close from '@/icons/close.svg?component'
import copy from '@/icons/copy.svg?component'
import curseForge from '@/icons/curseForge.svg?component'
import diamond from '@/icons/diamond.svg?component'
import discord from '@/icons/discord.svg?component'
import exclamation from '@/icons/exclamation.svg?component'
import facebook from '@/icons/facebook.svg?component'
import filter from '@/icons/filter.svg?component'
import gaugeMax from '@/icons/gaugeMax.svg?component'
import gaugeMid from '@/icons/gaugeMid.svg?component'
import help from '@/icons/help.svg?component'
import holiday from '@/icons/holiday.svg?component'
import info from '@/icons/info.svg?component'
import list from '@/icons/list.svg?component'
import loader from '@/icons/loader.svg?component'
import login from '@/icons/login.svg?component'
import logout from '@/icons/logout.svg?component'
import minus from '@/icons/minus.svg?component'
import multicraft from '@/icons/multicraft.svg?component'
import patreon from '@/icons/patreon.svg?component'
import people from '@/icons/people.svg?component'
import plus from '@/icons/plus.svg?component'
import recycle from '@/icons/recycle.svg?component'
import reddit from '@/icons/reddit.svg?component'
import reset from '@/icons/reset.svg?component'
import rocket from '@/icons/rocket.svg?component'
import search from '@/icons/search.svg?component'
import share from '@/icons/share.svg?component'
import shield from '@/icons/shield.svg?component'
import sortAsc from '@/icons/sortAsc.svg?component'
import sortDsc from '@/icons/sortDsc.svg?component'
import sparkles from '@/icons/sparkles.svg?component'
import star from '@/icons/star.svg?component'
import starOutline from '@/icons/starOutline.svg?component'
import tips from '@/icons/tips.svg?component'
import tooltipMinus from '@/icons/tooltipMinus.svg?component'
import tooltipMinusOutline from '@/icons/tooltipMinusOutline.svg?component'
import twitter from '@/icons/twitter.svg?component'
import warning from '@/icons/warning.svg?component'
import zzz from '@/icons/zzz.svg?component'

const icons = {
  // blizzard
  alliance,
  battlenet,
  horde,
  neutral,
  // regular
  account,
  addListing,
  caret,
  change,
  chat,
  check,
  close,
  copy,
  curseForge,
  diamond,
  discord,
  exclamation,
  facebook,
  filter,
  gaugeMax,
  gaugeMid,
  help,
  holiday,
  info,
  list,
  loader,
  login,
  logout,
  minus,
  multicraft,
  patreon,
  people,
  plus,
  recycle,
  reddit,
  reset,
  rocket,
  search,
  share,
  shield,
  sortAsc,
  sortDsc,
  sparkles,
  star,
  starOutline,
  tips,
  tooltipMinus,
  tooltipMinusOutline,
  twitter,
  warning,
  zzz,
}

export const iconNames = Object.keys(icons).sort()

export default {
  name: 'AppIcon',

  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 20,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    icon () {
      return icons[this.name]
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  display: inline-flex;
  width: var(--icon-size);
  height: var(--icon-size);

  &--loading {
    animation: spin 1s linear infinite;
  }
}
</style>
