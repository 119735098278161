import { getTierOptions, getOptionalReagentGroups } from '@/utils/config/filters'
import { ITEM_LEVEL_MODIFIERS, BASE_ITEM_LEVEL_MODIFIERS, TIER_3_ITEM_QUALITY_MODIFIERS } from '@/utils/config/reagentModifiers'
import { getSetCache } from '@/utils/helpers/cache'
import { getExpansionFromRecipe } from '@/utils/config/professions'

const qualityLevelModifiers = {
  1: 0,
  2: 3,
  3: 6,
  4: 9,
  5: 13,
}

export function getLevel ({ skill, filters, recipe, recipe: { difficulty } }) {
  return getSetCache('getLevel', { difficulty, skill, filters, recipe: recipe.id }, () => {
    const recipeItemLevel = recipe.items[0].level
    const recipeItemType = recipe.items[0].type
    const quality = getQuality({ difficulty, skill, filters, recipe })
    const optionalReagentsGroupOptions = getOptionalReagentGroups({ filters })

    const optionalReagentBaseLevel = _.reduce(filters.optionalReagentsIndex, (acc, optionalReagent, key) => {
      const item = optionalReagentsGroupOptions?.[key]?.items?.[optionalReagent]
      const level = BASE_ITEM_LEVEL_MODIFIERS?.[item?.id] ?? 0
      acc += Number(level)
      return acc
    }, 0)

    const optionalReagentLevel = _.reduce(filters.optionalReagentsIndex, (acc, optionalReagent, key) => {
      const item = optionalReagentsGroupOptions?.[key]?.items?.[optionalReagent]
      const level = ITEM_LEVEL_MODIFIERS[item?.id]?.min ?? 0
      acc += Number(level)
      return acc
    }, 0)

    const qualityItemLevel = recipeItemType === 'Profession' ? qualityLevelModifiers[quality] * 2 : qualityLevelModifiers[quality]

    if (optionalReagentLevel !== 0) return optionalReagentLevel + qualityItemLevel
    else if (optionalReagentBaseLevel !== 0) return optionalReagentBaseLevel + qualityItemLevel

    if ([1, null].includes(recipeItemLevel)) return '-'
    if (!_.includes(['Armor', 'Weapon', 'Profession'], recipeItemType)) return '-'

    return recipeItemLevel + qualityItemLevel
  })
}

export function getQuality ({ skill = 0, filters, recipe, recipe: { difficulty } }) {
  return getSetCache('getQuality', { difficulty, skill, filters, recipe }, () => {
    const optionalReagentsGroupOptions = getOptionalReagentGroups({ filters })
    const reagentDifficulty = _.reduce(filters.optionalReagentsIndex, (acc, optionalReagent, key) => {
      acc += Number(optionalReagentsGroupOptions?.[key]?.items?.[optionalReagent]?.difficulty ?? 0)
      return acc
    }, 0)
    const totalRecipeDifficulty =  difficulty + reagentDifficulty

    const expansionKey = getExpansionFromRecipe(recipe).key
    const relativeSkills = {
      1: (skill) / totalRecipeDifficulty,
      2: (skill + (difficulty * (TIER_3_ITEM_QUALITY_MODIFIERS[expansionKey] / 2))) / totalRecipeDifficulty,
      3: (skill + (difficulty * TIER_3_ITEM_QUALITY_MODIFIERS[expansionKey])) / totalRecipeDifficulty,
    }
    const breakPoints = {
      0: 0,
      1: 0.2,
      2: 0.5,
      3: 0.8,
      4: 1,
    }

    const tierOptions = getTierOptions({ filters })
    const tier = tierOptions?.[filters.tierIndex]?.value ?? 1

    const relativeSkill = relativeSkills[tier]
    const meetsMinimumSkill = !isNaN(relativeSkill) && (difficulty >= 40)

    let quality
    if (_.inRange(relativeSkill, breakPoints[0], breakPoints[1]) || !meetsMinimumSkill) quality = 1
    else if (_.inRange(relativeSkill, breakPoints[1], breakPoints[2])) quality = 2
    else if (_.inRange(relativeSkill, breakPoints[2], breakPoints[3])) quality = 3
    else if (_.inRange(relativeSkill, breakPoints[3], breakPoints[4])) quality = 4
    else if (_.inRange(relativeSkill, breakPoints[4], Infinity)) quality = 5

    if (recipe?.maxQuality === 3) quality = Math.ceil(quality / 2)

    return quality
  })
}
