const INITIAL_STATE = {
  error: [],
  warning: [],
  success: [],
  info: [],
}

export default {
  state: () => ({ ...INITIAL_STATE }),

  getters: {
    allAlertsOrderedByTimestamp: ({ error, warning, success, info }) => [
      ...getOrderedByTimestampAlerts({ error }),
      ...getOrderedByTimestampAlerts({ warning, success, info }),
    ],
  },

  actions: {
    async SHOW_ALERT ({ type, title, content, keepOpen = type === 'error', charactersPerSecond = 25 }) {
      const timestamp = Date.now()
      this.addAlert({ type, timestamp, title, content })

      if (keepOpen) return

      await _.$pause(getTimeout({ title, content, charactersPerSecond }))
      this.HIDE_ALERT({ type, timestamp })
    },

    HIDE_ALERT ({ type, timestamp }) {
      const types = _.keys(INITIAL_STATE)
      if (!types.includes(type)) return console.error(`HIDE_ALERT was passed an incorrect type`, { type })
      if (_.$isEmpty(timestamp)) return console.error(`HIDE_ALERT was passed an empty timestamp`, { timestamp })
      this.removeAlert({ type, timestamp })
    },

    addAlert ({ type, timestamp, title, content }) {
      if (_.every([type, timestamp, title, content], _.$isEmpty)) {
        return console.error(`addAlert was not passed valid params`, { type, timestamp, title, content })
      }
      if (_.find(this[type], ['content', content])) return
      this[type].push({ timestamp, title, content })
    },

    removeAlert ({ type, timestamp }) {
      const index = _.findIndex(this?.[type], ['timestamp', timestamp])
      if (index >= 0) this[type].splice(index, 1)
    },
  },
}

function getTimeout ({ title = '', content = '', charactersPerSecond = 25 } = {}) {
  const chunks = _.chunk(`${title}.${JSON.stringify(content)}`.split(''), charactersPerSecond).length
  return (chunks * 1000) + 1500
}

function getOrderedByTimestampAlerts (alertObject = {}) {
  return _(alertObject)
    .entries()
    .map(([type, alertList]) => alertList.map((alert) => ({ ...alert, type })))
    .flatten()
    .orderBy(['timestamp'], ['desc'])
    .value()
}
