<template>
  <span
    class="number-input__wrapper"
    :data-prefix="prefix"
    :data-suffix="suffix">
    <input
      ref="numberInput"
      v-model.number="inputValue"
      type="number"
      :class="['number-input', {
        'number-input--has-prefix': !!prefix,
        'number-input--has-suffix': !!suffix,
        'number-input--invalid': invalid,
        'number-input--disabled': disabled,
      }]"
      v-bind="{ max, min, step, required, disabled }">
  </span>
</template>

<script>
export default {
  props: {
    min: {
      type: [Number, null],
      default: 0,
    },
    max: {
      type: Number,
      default: 9999999,
    },
    step: {
      type: Number,
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Number, null],
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hardCap: {
      type: Boolean,
      default: false,
    },
  },

  emits: { 'update:model-value': (val) => _.isNumber(val) || _.isNull(val) },

  computed: {
    inputValue: {
      get () {
        return this.modelValue
      },

      set (newVal) {
        if ([null, ''].includes(newVal)) {
          this.$refs.numberInput.value = null
          this.$emit('update:model-value', null)
          return
        }

        const newNum = Math.round(Number(newVal))

        if (this.hardCap && newNum > this.max) {
          this.$refs.numberInput.value = this.max
          this.$emit('update:model-value', this.max)
          return
        }
        if (newNum < 0) {
          this.$refs.numberInput.value = 0
          this.$emit('update:model-value', 0)
          return
        }

        this.$emit('update:model-value', newNum)
        this.$refs.numberInput.value = newNum
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.number-input {
  display: flex;
  width: 100%;
  padding: 6px;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  background: none;
  color: rgb(var(--text));
  text-align: right;

  &[type="number"] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:invalid,
  &--invalid {
    border-color: rgb(var(--red));
  }

  &--disabled {
    background: rgb(var(--grey-dark-3));
    cursor: not-allowed;
  }

  &--has-prefix.number-input {
    padding-left: 16px;
  }

  &--has-suffix.number-input {
    padding-right: 16px;
  }

  &__wrapper {
    position: relative;

    &::before {
      content: attr(data-prefix);
      position: absolute;
      left: 0;
    }

    &::after {
      content: attr(data-suffix);
      display: inline-block;
      position: absolute;
      right: 8px;
      bottom: 6px;
      color: rgb(var(--gold));
      font-size: 0.9em;
      font-weight: 600;
    }
  }
}
</style>
