import { createRouter, createWebHistory } from 'vue-router'
import { useAlertsStore, useDevStore, useUserStore } from '@/stores'
import errorHandler from '@/utils/errors/handler'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: () => {
        return useDevStore().isMaintenanceMode
          ? { name: 'maintenance' }
          : { name: 'search' }
      },
    },
    {
      path: '/logout',
      name: 'logout',
      async beforeEnter (to, from, next) {
        const user = useUserStore()
        await user.LOGOUT()

        from.meta.requiresAuth
          ? next({ name: 'home' }, { replace: true })
          : next(from, { replace: true })
      },
      meta: {
        title: 'Logout',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/pages/MaintenancePage.vue'),
      meta: {
        title: 'Maintenance',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/help',
      name: 'help',
      component: () => import('@/pages/HelpPage.vue'),
      meta: {
        title: 'Help',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/pages/HelpPage.vue'),
      meta: {
        title: 'Privacy Policy',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/pages/HelpPage.vue'),
      meta: {
        title: 'Terms of Use',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/pages/HelpPage.vue'),
      meta: {
        title: 'FAQ',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/support',
      name: 'support',
      beforeEnter: (to, { name }, next) => {
        next({ name })
        window.open(import.meta.env.VITE_SUPPORT_URL, '_newtab')
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/pages/AccountPage.vue'),
      meta: {
        title: 'Account',
        requiresAuth: true,
      },
    },
    {
      path: '/listings',
      name: 'listings',
      component: () => import('@/pages/ListingsPage.vue'),
      meta: {
        title: 'Listings',
        requiresAuth: true,
      },
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('@/pages/SearchPage.vue'),
      meta: {
        title: 'Search',
        requiresAuth: false,
      },
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/pages/AdminPage.vue'),
      beforeEnter (from, to, next) {
        const user = useUserStore()

        const adminAccounts = import.meta.env.VITE_BATTLENET_ADMIN_ACCOUNTS.split(',')
        if (!adminAccounts.includes(user.auth.battleTag)) return next({ name: 'search' })

        next()
      },
      meta: {
        title: 'Admin',
        requiresAuth: true,
        canAccessDuringMaintenance: true,
      },
    },
    {
      path: '/oauth/battlenet/redirect',
      name: 'oauthBattlenetRedirect',
      component: () => import('@/pages/HelpPage.vue'),
      redirect: ({ query = {} }) => {
        const { pr, code, state } = query
        if (pr) return window.location.replace(`https://deploy-preview-${pr}--wow-forge-finder.netlify.app/.netlify/functions/auth-callback?code=${code}&state=${state}`)
        return { name: 'search' }
      },
      meta: {
        title: 'OAuth2 Battlenet Redirect',
        requiresAuth: false,
      },
    },
    {
      path: '/oauth/battlenet',
      name: 'oauthBattlenet',
      async beforeEnter ({ query = {} }, to, next) {
        const { token, error } = query
        if (error) {
          const alerts = useAlertsStore()

          const errors = {
            'ERROR_BATTLENET_PERMISSION': {
              'message': 'Some required permissions were not grant. Please visit our <a href="/faq#i-had-problems-creating-an-account-help">FAQ</a> to resolve the issue.',
              'title': 'Account creation failure',
            },
            'ERROR_BATTLENET_GENERAL': {
              'message': 'We are having trouble logging you in, please try again in a few moments',
              'title': 'Unauthorised',
            },
            'ERROR_FORGEFINDER_GENERAL': {
              'message': 'We are having trouble logging you in, please try again in a few moments',
              'title': 'Unauthorised',
            },
            'ERROR_GENERAL': {
              'message': 'We are having trouble logging you in, please try again in a few moments',
              'title': 'Unauthorised',
            },
          }

          alerts.SHOW_ALERT({
            type: 'error',
            title: errors[error].title,
            content: errors[error].message,
          })

          next({ name: 'logout' })
          return
        }

        const user = useUserStore()
        const redirectRoute = JSON.parse(localStorage.getItem('redirectRoute'))

        await user.LOGIN(token)

        if (!redirectRoute) return next({ name: 'search' })

        next(redirectRoute)
        localStorage.removeItem('redirectRoute')
      },
      meta: {
        title: 'OAuth2 Battlenet',
        requiresAuth: false,
      },
    },
    // Dev routes
    ...(!import.meta.env.DEV ? [] : [
      {
        path: '/sandbox',
        name: 'sandbox',
        component: () => import('@/pages/SandboxPage.vue'),
        beforeEnter (from, to, next) {
          const user = useUserStore()

          const adminAccounts = import.meta.env.VITE_BATTLENET_ADMIN_ACCOUNTS.split(',')
          if (!adminAccounts.includes(user.auth.battleTag)) return next({ name: 'search' })

          next()
        },
        meta: {
          title: 'Sandbox',
          requiresAuth: false,
          canAccessDuringMaintenance: true,
        },
      },
    ]),
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/pages/404Page.vue'),
      meta: {
        title: '404',
        requiresAuth: false,
        canAccessDuringMaintenance: true,
      },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const user = useUserStore()
  const dev = useDevStore()

  if (to.name === 'logout') return next()

  if (_.isNull(dev.isMaintenanceMode)) await useDevStore().CHECK_MAINTENANCE_MODE()
  if (dev.isMaintenanceMode && !to.meta.canAccessDuringMaintenance) {
    await user.LOGOUT()
    return next({ name: 'maintenance' })
  }
  if (!dev.isMaintenanceMode && to.name === 'maintenance') return next({ name: 'home' })

  const { requiresAuth } = to.meta
  const { isAuthenticated } = user

  if (!isAuthenticated && requiresAuth) {
    await user.LOGOUT()
    return next({ name: 'home' })
  }

  document.title = to.meta.title
    ? `ForgeFinder | ${to.meta.title}`
    : 'ForgeFinder'

  next()
})

router.onError((err) => errorHandler(err))

export default router
