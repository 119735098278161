export const WOW_EXP_CLASSIC = 'classic'
export const WOW_EXP_TBC = 'tbc'
export const WOW_EXP_WOLTK = 'woltk'
export const WOW_EXP_CATA = 'cata'
export const WOW_EXP_MOP = 'mop'
export const WOW_EXP_WOD = 'wod'
export const WOW_EXP_LEGION = 'legion'
export const WOW_EXP_BFA = 'bfa'
export const WOW_EXP_SL = 'sl'
export const WOW_EXP_DF = 'df'
export const WOW_EXP_TWW = 'tww'
export const WOW_EXP_MIDNIGHT = 'midnight'
export const WOW_EXP_TLT = 'tlt'

export const WOW_EXP_CURRENT = WOW_EXP_TWW

const professions = {
  [WOW_EXP_CLASSIC]: {
    key: WOW_EXP_CLASSIC,
    gameVersion: 1,
    isCurrent: false,
    name: 'Classic',
  },
  [WOW_EXP_TBC]: {
    key: WOW_EXP_TBC,
    gameVersion: 2,
    isCurrent: false,
    name: 'The Burning Crusade',
  },
  [WOW_EXP_WOLTK]: {
    key: WOW_EXP_WOLTK,
    gameVersion: 3,
    isCurrent: false,
    name: 'Wrath of the Lich King',
  },
  [WOW_EXP_CATA]: {
    key: WOW_EXP_CATA,
    gameVersion: 4,
    isCurrent: false,
    name: 'Cataclysm',
  },
  [WOW_EXP_MOP]: {
    key: WOW_EXP_MOP,
    gameVersion: 5,
    isCurrent: false,
    name: 'Mists of Pandaria',
  },
  [WOW_EXP_WOD]: {
    key: WOW_EXP_WOD,
    gameVersion: 6,
    isCurrent: false,
    name: 'Warlords of Draenor',
  },
  [WOW_EXP_LEGION]: {
    key: WOW_EXP_LEGION,
    gameVersion: 7,
    isCurrent: false,
    name: 'Legion',
  },
  [WOW_EXP_BFA]: {
    key: WOW_EXP_BFA,
    gameVersion: 8,
    isCurrent: false,
    name: 'Battle for Azeroth',
  },
  [WOW_EXP_SL]: {
    key: WOW_EXP_SL,
    gameVersion: 9,
    isCurrent: false,
    name: 'Shadowlands',
  },
  [WOW_EXP_DF]: {
    key: WOW_EXP_DF,
    gameVersion: 10,
    isCurrent: false,
    name: 'Dragonflight',
  },
  [WOW_EXP_TWW]: {
    key: WOW_EXP_TWW,
    gameVersion: 11,
    isCurrent: true,
    name: 'The War Within',
  },
  [WOW_EXP_MIDNIGHT]: {
    key: WOW_EXP_MIDNIGHT,
    gameVersion: 12,
    isCurrent: false,
    name: 'Midnight',
  },
  [WOW_EXP_TLT]: {
    key: WOW_EXP_TLT,
    gameVersion: 13,
    isCurrent: false,
    name: 'The Last Titan',
  },
}

export default professions
