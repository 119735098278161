<template>
  <span
    :class="['checkbox', {
      'checkbox--indeterminate': indeterminate,
      'checkbox--checked': modelValue,
      'checkbox--disabled': disabled,
      'checkbox--readonly': readonly,
      'checkbox--red': red,
    }]"
    :style="`--checkbox-size: ${size}px;`"
    @click.stop="handleClick">
    <AppIcon
      v-if="indeterminate"
      class="checkbox__icon"
      :size="size * 0.8"
      name="minus"/>
    <AppIcon
      v-else-if="invalid"
      class="checkbox__icon"
      :size="size * 0.8"
      name="exclamation"/>
    <AppIcon
      v-else-if="modelValue"
      class="checkbox__icon"
      :size="size * 0.8"
      name="check"/>
  </span>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Boolean, null],
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
  },

  emits: { 'update:model-value': (val) => _.isBoolean(val) || _.isNull(val) },

  methods: {
    handleClick () {
      if (this.disabled || this.readonly) return
      this.$emit('update:model-value', _.some([this.indeterminate, !this.modelValue]))
    },
  },
}
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  display: none;
}

.checkbox {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: 2px solid rgb(var(--gold-dark-2));
  border-radius: 4px;
  background: none;
  cursor: pointer;

  &--checked,
  &--indeterminate {
    color: rgb(var(--grey-dark-3));
  }

  &--checked {
    border-color: rgb(var(--gold));
    background: rgb(var(--gold));
  }

  &--indeterminate {
    border-color: rgb(var(--gold-dark-1));
    background: rgb(var(--gold-dark-1));
  }

  &--disabled {
    border-color: rgb(var(--grey-dark-1));
    cursor: not-allowed;
  }

  &--readonly {
    border-color: rgb(var(--grey)) !important;
    background: none !important;
    color: rgb(var(--grey));
    cursor: default;
  }

  &--red {
    border-color: rgb(var(--red)) !important;

    &.checkbox--checked {
      background: rgb(var(--red));
    }
  }
}
</style>
