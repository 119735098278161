/* eslint-disable no-restricted-imports */
import AppButton from '@/components/AppButton.vue'
import AppCheckbox from '@/components/AppCheckbox.vue'
import AppIcon from '@/components/AppIcon.vue'
import AppMessage from '@/components/AppMessage.vue'
import AppModal from '@/components/AppModal.vue'
import AppNumberInput from '@/components/AppNumberInput.vue'
import ChangelogStats from '@/components/ChangelogStats.vue'
import CopyBtn from '@/components/CopyBtn.vue'
import DonateBtn from '@/components/DonateBtn.vue'
import QualityIcon from '@/components/QualityIcon.vue'
import WowheadTooltip from '@/components/WowheadTooltip.vue'
import { defineAsyncComponent } from 'vue'

export default function registerGlobalComponents (app) {
  for (const [name, component] of Object.entries({
    AppButton,
    AppCheckbox,
    AppIcon,
    AppMessage,
    AppModal,
    AppNumberInput,
    ChangelogStats,
    CopyBtn,
    DonateBtn,
    QualityIcon,
    WowheadTooltip,
    // Markdown examples
    QualityWidgetExample: defineAsyncComponent(() => import('@/components/examples/QualityWidgetExample.vue')),
  })) {
    app.component(name, component)
  }
}
