<template>
  <component
    v-if="shareComponent"
    :class="['share-btn', { 'share-btn--with-text': !$_.isEmpty(text) }]"
    :style="`--share-btn-size: ${size}px;`"
    :data-hide-tooltip="!$_.isEmpty(text)"
    :data-tooltip-b="`Share to ${$_.upperFirst(name)}`"
    :is="shareComponent"
    v-bind="{ shareOptions }">
    <AppIcon
      :size="size - 10"
      v-bind="{ name }"/>
    {{ text }}
  </component>
  <span
    v-else-if="canShare && isNavItem"
    class="share-btn share-btn--with-text"
    :style="`--share-btn-size: ${size}px;`"
    :data-hide-tooltip="$attrs['data-hide-tooltip']"
    :data-tooltip-b="$attrs['data-tooltip-b']"
    @click="share">
    <AppIcon
      :size="size - 10"
      name="share"/>
    <span class="share-btn__text">{{ text }}</span>
  </span>
  <span
    v-else-if="canShare"
    :class="['share-btn', { 'share-btn--with-text': !$_.isEmpty(text) }]"
    :style="`--share-btn-size: ${size}px;`"
    :data-hide-tooltip="!$_.isEmpty(text)"
    :data-tooltip-b="$attrs['data-tooltip-b'] ?? 'Share anywhere!'"
    @click="share">
    <AppIcon
      :size="size - 10"
      name="share"/>
    <span
      v-if="!$_.isEmpty(text)"
      class="share-btn__text">{{ text }}</span>
  </span>
</template>

<script>
// https://www.npmjs.com/package/vue-socials
import { SFacebook, SReddit, STwitter } from 'vue-socials'

const url = 'https://www.wowforgefinder.com'
const title = 'ForgeFinder'
const text = 'Need help with craft orders? Check out ForgeFinder!'
const hashtags = ['wow', 'gaming', 'craftOrders', 'new', 'worldOfWarcraft', 'app', 'forgeFinder']

export default {
  props: {
    name: {
      type: String,
      validator: (name) => ['share', 'facebook', 'twitter', 'reddit'].includes(name),
      default: 'share',
    },

    size: {
      type: Number,
      default: 40,
    },

    text: {
      type: String,
      default: '',
    },

    isNavItem: {
      type: Boolean,
      default: false,
    },
  },

  inject: ['$_'],

  components: {
    SFacebook,
    SReddit,
    STwitter,
  },

  data: () => ({
    shareOptions: {
      url,
      text,
      title,
      quote: text,
      hashtags,
      hashtag: '#WorldOfWarcraft',
      selfText: false,
    },
  }),

  computed: {
    shareComponent () {
      return {
        facebook: SFacebook,
        twitter: STwitter,
        reddit: SReddit,
      }[this.name] ?? null
    },

    canShare () {
      return navigator?.canShare ?? false
    },
  },

  methods: {
    share () {
      navigator.share({
        url,
        text,
        title,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.share-btn {
  display: flex !important;
  place-items: center;
  justify-content: center;
  width: var(--share-btn-size);
  height: var(--share-btn-size);
  transition: background 0.25s ease-in-out !important;
  border-radius: var(--share-btn-size);
  background: rgb(var(--blue));
  color: rgb(var(--white));
  gap: 4px;
  cursor: pointer;

  :deep(.icon) {
    color: rgb(var(--white));
  }

  &:hover,
  &:focus {
    background: rgb(var(--blue-light-1));
  }

  &--with-text {
    width: auto;
  }
}
</style>
