import api from '@/utils/api'
import jwtDecode from 'jwt-decode'
import { getNonce } from '@/utils/api/battlenet/config'
import { useWowStore, useAlertsStore } from '@/stores/index'
import router from '@/router'
import Character from '@/utils/entities/Character'

export default {
  state: () => ({
    accounts: {
      eu: [],
      us: [],
    },
    characters: {
      eu: [],
      us: [],
    },
    favourites: {
      characters: [],
      crafters: [],
      recipes: [],
      realms: [],
    },
    isOnHoliday: false,
    auth: {
      accessToken: localStorage.getItem('battlenet.accessToken') || '',
      battlenetId: localStorage.getItem('battlenet.battlenetId') || '',
      battleTag: localStorage.getItem('battlenet.battleTag') || '',
      characterIds: localStorage.getItem('battlenet.characterIds') || '',
      jwt: localStorage.getItem('battlenet.jwt') || '',
      regions: localStorage.getItem('battlenet.regions')?.split(',') ?? [],
    },
  }),

  getters: {
    allRealmCharacters: ({ characters: { eu, us } }) => _([ ...eu, ...us ])
      .values()
      .flatten()
      .groupBy('realm.id')
      .value(),

    allCharacters: ({ characters: { eu, us } }) => _([ ...eu, ...us ])
      .values()
      .flatten()
      .value(),

    isAuthenticated: ({ auth: { jwt } }) => {
      if (!jwt) return false

      const exp = jwtDecode(jwt).exp
      const currentTime = Math.round(Date.now() / 1000)

      return currentTime < exp
    },

    getCharacterById: ({ allCharacters }) => (id) => _.find(allCharacters, { id }),
  },

  actions: {
    async GET_FAVOURITES () {
      const { battlenetId } = this.auth
      const { data: crafters } = await api.db.getFavouriteCrafters()
      const { data: recipes } = await api.db.getFavouriteRecipes()
      const { data: realms } = await api.db.getFavouriteRealms()
      const { data: characters } = await api.db.getCharacters({ battlenetId })
      this.favourites.crafters = crafters
      this.favourites.recipes = recipes
      this.favourites.realms = realms
      this.favourites.characters = _(characters)
        .filter('isFavourite')
        .map((character) => new Character(character))
        .value()
    },

    async ADD_FAVOURITE_CHARACTER ({ characterId }) {
      const { battlenetId } = this.auth
      const character = this.getCharacterById(characterId)

      const { ok, data } = await api.db.upsertCharacters([
        new Character({ ...character, battlenetId, isFavourite: true }).dbCharacter,
      ])

      if (ok) this.favourites.characters.push(data?.[0])
    },

    async REMOVE_FAVOURITE_CHARACTER ({ characterId }) {
      const { battlenetId } = this.auth
      const character = this.getCharacterById(characterId)

      const { ok } = await api.db.upsertCharacters([
        new Character({ ...character, battlenetId, isFavourite: false }).dbCharacter,
      ])

      if (ok) _.remove(this.favourites.characters, { id: characterId })
    },

    async ADD_FAVOURITE_CRAFTER ({ characterId }) {
      const { battlenetId } = this.auth
      const { ok } = await api.db.upsertFavouriteCrafter({ characterId, battlenetId })
      if (ok) this.favourites.crafters.push({ characterId, battlenetId })
    },

    async REMOVE_FAVOURITE_CRAFTER ({ characterId }) {
      const { battlenetId } = this.auth
      const { ok } = await api.db.removeFavouriteCrafter({ characterId, battlenetId })
      if (ok) _.remove(this.favourites.crafters, { characterId })
    },

    async ADD_FAVOURITE_RECIPE ({ recipeId }) {
      const { battlenetId } = this.auth
      const { ok } = await api.db.upsertFavouriteRecipe({ recipeId, battlenetId })
      if (ok) this.favourites.recipes.push({ recipeId, battlenetId })
    },

    async REMOVE_FAVOURITE_RECIPE ({ recipeId }) {
      const { battlenetId } = this.auth
      const { ok } = await api.db.removeFavouriteRecipe({ recipeId, battlenetId })
      if (ok) _.remove(this.favourites.recipes, { recipeId })
    },

    async ADD_FAVOURITE_REALM ({ realmId }) {
      const { battlenetId } = this.auth
      const { ok } = await api.db.upsertFavouriteRealm({ realmId, battlenetId })
      if (ok) this.favourites.realms.push({ realmId, battlenetId })
    },

    async REMOVE_FAVOURITE_REALM ({ realmId }) {
      const { battlenetId } = this.auth
      const { ok } = await api.db.removeFavouriteRealm({ realmId, battlenetId })
      if (ok) _.remove(this.favourites.realms, { realmId })
    },

    async GET_HOLIDAY_MODE () {
      const { battlenetId } = this.auth
      this.isOnHoliday = await api.db.getHolidayMode({ battlenetId })
    },

    async GET_PROFILE () {
      const { battlenetId, regions } = this.auth
      for (const region of regions) {
        const { data } = await api.battlenet.user.getProfile({ region })
        if (!data) {
          this.accounts[region] = []
          this.characters[region] = []
          continue
        }

        this.accounts[region] = _.map(data?.wowAccounts, 'id')

        this.characters[region] = _.transform(data?.wowAccounts, (accChars, { characters }) => {
          for (const character of characters) {
            accChars.push(Character.fromApi({
              apiCharacter: character,
              battlenetId,
              region,
            }))
          }
        }, [])
      }
    },

    async DELETE_ACCOUNT () {
      const { battlenetId } = this.auth
      const alerts = useAlertsStore()
      const responses = await Promise.all([
        await api.db.removeFavouriteCrafters({ battlenetId }),
        await api.db.removeFavouriteRecipes({ battlenetId }),
        await api.db.removeFavouriteRealms({ battlenetId }),
        await api.db.removeHolidayMode({ battlenetId }),
        await api.db.removeListingsByBattlenetId({ battlenetId }),
      ])
      const { ok: charactersDeletedOk } = await api.db.removeCharacters({ battlenetId })
      const errors = _(responses).map(({ errors = [] }) => errors).flatten().value()
      if (!errors.length && charactersDeletedOk) {
        await router.push({ name: 'logout' })
        alerts.SHOW_ALERT({
          type: 'success',
          title: 'Deleted account',
          content: 'Successfully deleted all data associated with your battlenetId',
        })
        return
      }

      alerts.SHOW_ALERT({
        type: 'error',
        title: 'Problem deleting your account data, please try again',
        content: errors,
      })
    },

    async LOGIN (jwt) {
      try {
        const { accessToken, battlenetId, battleTag, regions, characterIds, state } = jwtDecode(jwt)
        const decodedJWT = { accessToken, battlenetId, battleTag, regions, characterIds, jwt }

        if (getNonce() !== state) return

        for (const [key, value] of _.entries(decodedJWT)) {
          this.auth[key] = value
          localStorage.setItem(`battlenet.${key}`, _.isArray(value) ? value.join(',') : value)
        }

        const wow = useWowStore()
        await wow.GET_REALMS()
        await wow.GET_RECIPES()
        await this.GET_PROFILE()
      } catch (err) {
        const alerts = useAlertsStore()

        alerts.SHOW_ALERT({
          type: 'error',
          title: 'Unauthorised',
          content: 'We are having trouble logging you in, please try again in a few moments',
        })

        await router.push({ name: 'logout' })
      }
    },

    async LOGOUT () {
      for (const key in this.auth) localStorage.removeItem(`battlenet.${key}`)
      this.$reset()

      const wow = useWowStore()
      await wow.GET_REALMS()
      await wow.GET_RECIPES()
    },

    searchRegionCharacters ({ searchTerm = '', canFulfilCraftOrders = null }) {
      const { characterIds } = this.auth
      return _(this.characters)
        .values()
        .flatten()
        .groupBy('realm.region')
        .transform((acc, regionChars, region) => {
          acc[region] = !regionChars.length ? [] : _(regionChars)
            .filter((char) => _.every([
              char.nameSlug.includes(searchTerm.toLowerCase()),
              _.isNull(canFulfilCraftOrders) || characterIds.includes(char.id) === canFulfilCraftOrders,
            ]))
            .groupBy('realm.id')
            .value()
        }, {})
        .value()
    },

    async TOGGLE_ON_HOLIDAY (newVal = !this.isOnHoliday) {
      const { battlenetId } = this.auth
      newVal
        ? await api.db.upsertHolidayMode({ battlenetId })
        : await api.db.removeHolidayMode({ battlenetId })
      this.isOnHoliday = newVal
      const { data } = await api.db.getCharacters({ battlenetId })
      const characters = data
        .filter((character) => !_.isEmpty(character))
        .map((character) => ({ ...character, onHoliday: newVal }))
      await api.db.upsertCharacters(characters)
    },
  },
}
