import expansions, {
  WOW_EXP_CLASSIC,
  WOW_EXP_DF,
  WOW_EXP_TWW,
} from './expansions'

const professions = {
  164: {
    name: 'Blacksmithing',
    tier: {
      2477: WOW_EXP_CLASSIC,
      2822: WOW_EXP_DF,
      2872: WOW_EXP_TWW,
    },
  },
  202: {
    name: 'Engineering',
    tier: {
      2506: WOW_EXP_CLASSIC,
      2827: WOW_EXP_DF,
      2875: WOW_EXP_TWW,
    },
  },
  333: {
    name: 'Enchanting',
    tier: {
      2494: WOW_EXP_CLASSIC,
      2825: WOW_EXP_DF,
      2874: WOW_EXP_TWW,
    },
  },
  197: {
    name: 'Tailoring',
    tier: {
      2540: WOW_EXP_CLASSIC,
      2831: WOW_EXP_DF,
      2883: WOW_EXP_TWW,
    },
  },
  773: {
    name: 'Inscription',
    tier: {
      2514: WOW_EXP_CLASSIC,
      2828: WOW_EXP_DF,
      2878: WOW_EXP_TWW,
    },
  },
  165: {
    name: 'Leatherworking',
    tier: {
      2532: WOW_EXP_CLASSIC,
      2830: WOW_EXP_DF,
      2880: WOW_EXP_TWW,
    },
  },
  171: {
    name: 'Alchemy',
    tier: {
      2485: WOW_EXP_CLASSIC,
      2823: WOW_EXP_DF,
      2871: WOW_EXP_TWW,
    },
  },
  755: {
    name: 'Jewelcrafting',
    tier: {
      2524: WOW_EXP_CLASSIC,
      2829: WOW_EXP_DF,
      2879: WOW_EXP_TWW,
    },
  },
}

export function getExpansionFromRecipe ({ professionId, professionTierId }) {
  const expansionKey = professions
    ?.[professionId]
    ?.tier
    ?.[professionTierId] ?? 'Unknown'
  const expansion = expansions[expansionKey]
  return {
    ...expansion,
    key: expansionKey,
    name: expansion?.name ?? 'Unknown',
  }
}

export function getProfessionTiersByExpansion (expansionCode) {
  const result = []

  _.forEach(professions, (profession) => {
    _.forEach(profession.tier, (value, key) => {
      if (value === expansionCode) {
        result.push(Number(key))
      }
    })
  })

  return result
}

export default professions
