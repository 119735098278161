import api from '@/utils/api'
import professionsConfig from '@/utils/config/professions'
import { useAlertsStore, useUserStore, useWowStore } from '@/stores'
import Listing from '@/utils/entities/Listing'
import { format } from 'date-fns'
import Character from '@/utils/entities/Character'
import { WOW_EXP_CURRENT } from '@/utils/config/expansions'

export default {
  state: () => ({
    cached: {
      professionTiers: {},
      professionRecipes: {},
    },
    selected: {
      character: {},
      listings: [],
      professionTiers: {},
      professionRecipes: {},
    },
    hasFetched: {},
    isTourActive: false,
    filters: {
      tierIndex: 2,
      expansion: WOW_EXP_CURRENT,
    },
  }),

  getters: {
    allReagents: ({ selected }) => {
      const parseReagents = (originalReagent) => {
        const reagent = originalReagent
        const { item } = reagent
        return {
          isModified: false,
          id: reagent.reagentId,
          name: item.name,
          item,
        }
      }
      const parseModifiedReagents = (originalReagent) => {
        const reagent = originalReagent
        const { items } = reagent.modifiedReagent
        return items.map((item) => ({
          isModified: true,
          id: reagent.modifiedReagentId,
          name: reagent.modifiedReagent.name,
          item,
        }))
      }

      return !Object.keys(selected.professionRecipes).length
        ? []
        : _.chain(selected.professionRecipes)
          .reduce((acc, tiers) => {
            for (const [professionTier, tierRecipes] of _.entries(tiers)) {
              const reagents = _(tierRecipes)
                .map(({ reagents, modifiedReagents }) => [
                  ..._.map(reagents, parseReagents),
                  ..._(modifiedReagents).map(parseModifiedReagents).flatten().value(),
                ])
                .flatten()
                .map((reagent) => ({ ...reagent, ...{ professionTier } }))
                .uniqBy('item.id')
                .sortBy(['name', 'item.tier'])
                .value()
              acc = [...acc, ...reagents]
            }
            return acc
          }, [])
          .value()
    },
  },

  actions: {
    async GET_CHARACTER_RECIPES ({ character }) {
      const { realm: { slug: realm, region }, nameSlug: characterName, key: characterKey } = character
      const cachedData = _.cloneDeep(this.cached.professionRecipes?.[characterKey])

      this.selected.character = character
      this.selected.professionTiers = {}
      this.selected.professionRecipes = {}

      if (cachedData) {
        this.selected.professionRecipes = cachedData
        for (const tiers of _.values(cachedData)) {
          for (const tierId of _.keys(tiers)) {
            this.selected.professionTiers[tierId] = this.cached.professionTiers[tierId]
          }
        }
        return
      }

      const { data } = await api.battlenet.user.getProfessions({ region, realm, characterName })
      if (data) {
        for (const { id: professionId, tiers } of parseProfessions(data)) {
          for (const [tierId, { name }] of _.entries(tiers)) {
            this.selected.professionTiers[tierId] = name
            this.cached.professionTiers[tierId] = name
          }
          const knownRecipeIds = _(tiers)
            .values()
            .map((tier) => _.map(tier.knownRecipes, 'id'))
            .flatten()
            .value()
          await this.GET_CHARACTER_PROFESSION_REAGENTS({ characterKey, professionId, knownRecipeIds })
        }
      }
    },

    async GET_CHARACTER_PROFESSION_REAGENTS ({ characterKey, professionId, knownRecipeIds }) {
      const { data = [] } = await api.db.getProfessionRecipesAndRelations({ id: professionId })
      const knownRecipes = parseRecipes(data, knownRecipeIds, professionId)

      _.set(this.selected.professionRecipes, `${professionId}`, _.groupBy(knownRecipes, 'tierId'))

      if (!this.cached.professionRecipes[characterKey]) {
        this.cached.professionRecipes[characterKey] = {}
      }
      _.set(this.cached.professionRecipes[characterKey], `${professionId}`, _.groupBy(knownRecipes, 'tierId'))
    },

    async GET_CHARACTER_LISTINGS ({ character }) {
      const { battlenetId } = useUserStore().auth
      const { id: characterId } = character
      const { data = [] } = await api.db.getCharacterListings({ characterId, battlenetId })
      this.selected.listings = _.map(data, (listing) => ({ ...listing, providedReagentIds: _.flatten(_.map(listing.providedReagentIds, 'reagentId')) }))
    },

    async SAVE_LISTINGS ({ upserted, deleted }) {
      const results = []
      if (upserted.length) results.push(await this.UPSERT_CHARACTER_LISTINGS(upserted))
      if (deleted.length) results.push(await this.DELETE_CHARACTER_LISTINGS(deleted))

      return _.every(results)
    },

    async UPDATE_CHARACTER_LOGIN_DATES ({ characterIds = null } = {}) {
      const characters = await api.db.getCharacters({ characterIds })
      const wowStore = useWowStore()
      if (_.isEmpty(wowStore.allRealms)) await wowStore.GET_REALMS()
      const allRealms = wowStore.allRealms
      const cachedCharacters = {}
      const charactersToSave = []

      for (const character of characters.data) {
        const existingLoginDate = character.lastOnlineAt

        const realmObj = _.find(allRealms, ['id', character.realmId])

        const realm = realmObj.slug
        const region = realmObj.region

        const { data } = _.isEmpty(cachedCharacters[character.id])
          ? cachedCharacters[character.id] = await api.battlenet.user.getCharacterData({ region, realm, characterName: character.nameSlug })
          : cachedCharacters[character.id]

        // if the blizzard api fails, don't update this login date for now
        if (_.isNil(data?.lastLoginTimestamp)) continue

        if (existingLoginDate !== format(data.lastLoginTimestamp, 'yyyy-MM-dd\'T\'HH:mm:ssxxx')) {
          charactersToSave.push({ ...character, ...{ lastOnlineAt: format(data.lastLoginTimestamp, 'yyyy-MM-dd\'T\'HH:mm:ssxxx') } })
        }
      }

      if (!_.isEmpty(charactersToSave)) await api.db.upsertCharacters(charactersToSave)
    },

    async UPSERT_CHARACTER_LISTINGS (listings) {
      const userStore = useUserStore()
      const characters = _(listings)
        .map(({ characterId }) => {
          const character = userStore.getCharacterById(characterId)
          return new Character({ ...character, onHoliday: userStore.isOnHoliday }).dbCharacter
        })
        .uniqBy('id')
        .value()

      const { ok: characterOk } = await api.db.upsertCharacters(characters)
      if (!characterOk) {
        useAlertsStore().SHOW_ALERT({
          type: 'error',
          title: 'Failed',
          content: 'Problem with listing update',
        })
        return false
      }

      const { ok } = await api.db.upsertListings(_.map(listings, 'forListingDB'))
      if (!ok) {
        useAlertsStore().SHOW_ALERT({
          type: 'error',
          title: 'Failed',
          content: 'Problem with listing update',
        })
        return false
      }

      await this.UPDATE_CHARACTER_LOGIN_DATES({ characterIds: _.map(characters, 'id') })

      const reagents = _.flatten(_.map(listings, 'forListingProvidedReagentsDB'))

      if (!_.isEmpty(reagents)) {
        const { ok } = await api.db.upsertListingProvidedReagents(reagents)
        if (!ok) {
          useAlertsStore().SHOW_ALERT({
            type: 'error',
            title: 'Failed',
            content: 'Problem with listing update',
          })
          return false
        }
      }

      const listingsChunks = _.chunk(listings, 10)
      for (const listingsChunk of listingsChunks) {
        const removedReagentsChunk = _.flatten(_.map(listingsChunk, 'forListingProvidedReagentsDBFromCache'))
        const reagentsChunk = _.flatten(_.map(listingsChunk, 'forListingProvidedReagentsDB'))

        const reagentDiffChunk = _.differenceWith(
          removedReagentsChunk,
          reagentsChunk,
          (a, b) => _.every([a.listingId === b.listingId, a.reagentId === b.reagentId]),
        )
        if (!_.isEmpty(reagentDiffChunk)) {
          const { ok } = await api.db.removeListingProvidedReagents(reagentDiffChunk)
          if (!ok) {
            useAlertsStore().SHOW_ALERT({
              type: 'error',
              title: 'Failed',
              content: 'Problem with listing update',
            })
            return false
          }
        }
      }

      for (const listing of listings) {
        const index = _.findIndex(this.selected.listings, ['recipeId', listing.recipeId])
        index !== -1
          ? this.selected.listings[index] = new Listing(_.omit(listing, ['cache', 'wasImported']))
          : this.selected.listings.push(new Listing(_.omit(listing, ['cache', 'wasImported'])))
      }
      useAlertsStore().SHOW_ALERT({
        type: 'success',
        title: 'Success',
        content: listings.length === 1
          ? `${listings.length} Listing saved`
          : `${listings.length} Listings saved`,
      })

      return true
    },

    async DELETE_CHARACTER_LISTINGS (listings) {
      const listingsChunks = _.chunk(listings, 10)
      for (const listingsChunk of listingsChunks) {
        const { ok } = await api.db.removeListings(_.map(listingsChunk, 'forListingDB'))
        if (!ok) {
          useAlertsStore().SHOW_ALERT({
            type: 'error',
            title: 'Failed',
            content: 'Problem with listing deletion',
          })
          return false
        }

        const reagents = _.flatten(_.map(listingsChunk, 'forListingProvidedReagentsDB'))
        if (!_.isEmpty(reagents)) {
          const { ok } = await api.db.removeListingProvidedReagents(reagents)
          if (!ok) {
            useAlertsStore().SHOW_ALERT({
              type: 'error',
              title: 'Failed',
              content: 'Problem with listing update',
            })
            return false
          }
        }
      }

      for (const listing of listings) {
        const index = _.findIndex(this.selected.listings, ['recipeId', listing.recipeId])
        if (index === -1) console.error('listing not found', { recipeId: listing.recipeId, listingId: listing.id })
        this.selected.listings.splice(index, 1)
      }
      useAlertsStore().SHOW_ALERT({
        type: 'success',
        title: 'Success',
        content: listings.length === 1
          ? `${listings.length} Listing deleted`
          : `${listings.length} Listings deleted`,
      })
      return true
    },

    setTourActive (newVal) {
      this.isTourActive = newVal
    },
  },
}

function parseProfessions (data) {
  const { recipes } = useWowStore()

  if (_.isEmpty(data?.primaries)) return []
  return data.primaries
    .map(({ profession, tiers }) => ({
      ...profession,
      tiers: _(tiers)
        .filter(({ tier: { id: tierId } }) => tierId in (professionsConfig?.[profession.id]?.tier ?? {}))
        .transform((acc, tier) => {
          const tierData = _.omit({ ...tier, ...tier.tier }, ['tier'])
          tierData.knownRecipes = tierData.knownRecipes
            .filter(({ id: recipeId }) => _.map(recipes, 'id').includes(recipeId))
            .map(({ id }) => _.find(recipes, ['id', id]))
          if (tierData.knownRecipes.length) acc[tier.tier.id] = tierData
        }, {})
        .value(),
    }))
    .filter(({ id, tiers }) => _.every([
      id in professionsConfig,
      !_.isEmpty(tiers),
    ]))
}

function parseRecipes (data, knownRecipeIds, professionId) {
  const removeUnusableReagents = ({ crafterOnly, optional }) => _({
    isNotCrafterOnly: !crafterOnly,
    isNotOptional: !optional,
  }).values().every()

  return data
    .map((originalRecipe) => {
      const recipe = _.$camelCaseKeys(originalRecipe)
      return {
        recipeId: recipe.id,
        canCraft: knownRecipeIds.includes(recipe.id),
        professionId: Number(professionId),
        tierId: recipe.tierId,
        name: recipe.name,
        reagents: _(recipe.reagents)
          .filter(removeUnusableReagents)
          .sortBy('order')
          .value(),
        modifiedReagents: _(recipe.modifiedReagents)
          .filter(removeUnusableReagents)
          .sortBy('order')
          .value(),
        item: recipe.item[0],
        maxQuality: recipe.maxQuality,
        difficulty: recipe.difficulty,
        maxSkill: recipe.maxSkill,
        maxInspiration: recipe.maxInspiration,
        maxInspirationPercent: recipe.maxInspirationPercent,
        maxMulticraftPercent: recipe.maxMulticraftPercent,
      }
    })
    .filter(({ canCraft }) => canCraft)
}
