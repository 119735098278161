import router from '@/router'
import { useAlertsStore } from '@/stores'
import AuthError from '@/utils/errors/AuthError'
import Rollbar from 'rollbar'

// https://github.com/clinggroup/vite-plugin-rollbar
const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.PROD ? 'PROD' : 'DEV',
    client: {
      /* eslint-disable camelcase */
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.CURRENT_VERSION,
        guess_uncaught_frames: true,
      },
      /* eslint-enable camelcase */
    },
  },
  autoInstrument: import.meta.env.PROD, // disable rollbar data collection on dev
})

export default (err) => {
  if (import.meta.env.PROD) rollbar.error(err)
  if (import.meta.env.DEV) console.error(err)
  if (err instanceof AuthError) {
    const alerts = useAlertsStore()
    alerts.SHOW_ALERT({
      type: 'error',
      title: 'Unauthorised',
      content: 'Session expired',
    })
    router.push({ name: 'logout' })
  }
}
