import {
  ITEM_CONCENTRATED_PRIMAL_INFUSION,
  ITEM_ENCHANTED_ASPECTS_AWAKENED_CREST,
  ITEM_ENCHANTED_ASPECTS_DREAMING_CREST,
  ITEM_ENCHANTED_ASPECTS_SHADOWFLAME_CREST,
  ITEM_ENCHANTED_WHELPLINGS_AWAKENED_CREST,
  ITEM_ENCHANTED_WHELPLINGS_DREAMING_CREST,
  ITEM_ENCHANTED_WHELPLINGS_SHADOWFLAME_CREST,
  ITEM_ENCHANTED_WYRMS_AWAKENED_CREST,
  ITEM_ENCHANTED_WYRMS_DREAMING_CREST,
  ITEM_ENCHANTED_WYRMS_SHADOWFLAME_CREST,
  ITEM_PRIMAL_INFUSION,
  ITEM_SPARK_OF_AWAKENING,
  ITEM_SPARK_OF_DREAMS,
  ITEM_SPARK_OF_INGENUITY,
  ITEM_SPARK_OF_SHADOWFLAME,
  ITEM_TITAN_TRAINING_MATRIX_I,
  ITEM_TITAN_TRAINING_MATRIX_II,
  ITEM_TITAN_TRAINING_MATRIX_III,
  ITEM_TITAN_TRAINING_MATRIX_IV,
  ITEM_TITAN_TRAINING_MATRIX_V,
} from '@/utils/config/expansions/dragonflight/reagents'

export const TIER_3_ITEM_QUALITY_MODIFIER = 0.25

export const BASE_ITEM_LEVEL_MODIFIERS = {
  [ITEM_SPARK_OF_INGENUITY]: 382,
  [ITEM_SPARK_OF_SHADOWFLAME]: 411,
  [ITEM_SPARK_OF_DREAMS]: 450,
  [ITEM_SPARK_OF_AWAKENING]: 489,
}

export const ITEM_LEVEL_MODIFIERS = {
  [ITEM_TITAN_TRAINING_MATRIX_I]: { min: 330, max: 343 },
  [ITEM_TITAN_TRAINING_MATRIX_II]: { min: 343, max: 356 },
  [ITEM_TITAN_TRAINING_MATRIX_III]: { min: 356, max: 369 },
  [ITEM_TITAN_TRAINING_MATRIX_IV]: { min: 369, max: 382 },
  [ITEM_TITAN_TRAINING_MATRIX_V]: { min: 382, max: 395 },
  [ITEM_PRIMAL_INFUSION]: { min: 395, max: 408 },
  [ITEM_CONCENTRATED_PRIMAL_INFUSION]: { min: 408, max: 421 },
  [ITEM_ENCHANTED_WHELPLINGS_SHADOWFLAME_CREST]: { min: 395, max: 408 },
  [ITEM_ENCHANTED_WYRMS_SHADOWFLAME_CREST]: { min: 424, max: 437 },
  [ITEM_ENCHANTED_ASPECTS_SHADOWFLAME_CREST]: { min: 434, max: 447 },
  [ITEM_ENCHANTED_WHELPLINGS_DREAMING_CREST]: { min: 434, max: 447 },
  [ITEM_ENCHANTED_WYRMS_DREAMING_CREST]: { min: 463, max: 476 },
  [ITEM_ENCHANTED_ASPECTS_DREAMING_CREST]: { min: 473, max: 486 },
  [ITEM_ENCHANTED_WHELPLINGS_AWAKENED_CREST]: { min: 473, max: 486 },
  [ITEM_ENCHANTED_WYRMS_AWAKENED_CREST]: { min: 502, max: 515 },
  [ITEM_ENCHANTED_ASPECTS_AWAKENED_CREST]: { min: 512, max: 525 },
}
