import AuthError from '@/utils/errors/AuthError'

export async function callApi ({
  headers = {},
  method = 'GET',
  path = console.warn('[callApi] missing param path'),
  query = console.warn('[callApi] missing param queryParams'),
  autoProvideToken = true,
  returnCamelCaseKeys = true,
  retries = 0,
} = {}) {
  const token = getAccessToken()

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const queryParams = new URLSearchParams({
    ...query,
    locale: 'en_GB',
  }).toString()
  
  const headerParams = {
    ...headers,
    ...(autoProvideToken ? { Authorization: `Bearer ${token}` }: {}),
  }
  
  const endpoint = `${path}?${queryParams}`

  for (let i = 0; i <= retries; i++) {
    const res = await fetch(endpoint, { method, headers: headerParams })
    const { status, statusText, ok } = res

    const isServerError = _.inRange(status, 500, 599)
    const isClientTimeout = status === 408
    const isUnauthorisedError = status === 401
    const isForbiddenError = status === 403
    const isMissingError = status === 404

    if ((isServerError || isClientTimeout || isMissingError) && i < retries) {
      await delay(5000)
      continue
    }

    if (isUnauthorisedError || isForbiddenError) throw new AuthError()

    if (ok) {
      const dataRes = await res.json()
      const data = returnCamelCaseKeys ? _.$camelCaseKeys(dataRes) : dataRes
      return {
        data,
        status,
        statusText,
        ok,
      }
    }

    return {
      errors: [`${status}: ${statusText}`],
      status,
      statusText,
      ok,
    }
  }
}

function getAccessToken () {
  return localStorage.getItem('battlenet.accessToken')
}

export function generateNonce () {
  const nonce = `${Date.now()}-${_.random(0, 100000000)}`
  sessionStorage.setItem('nonce', nonce)

  return nonce
}

export function getNonce () {
  return sessionStorage.getItem('nonce')
}
