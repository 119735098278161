import { useWowStore } from '@/stores'

export default class Character {
  constructor ({
    battlenetId = console.warn('[Character] "battlenetId" was not supplied'),
    id = console.warn('[Character] "id" was not supplied'),
    realmId = console.warn('[Character] "realmId" was not supplied'),
    name = console.warn('[Character] "name" was not supplied'),
    classSlug = console.warn('[Character] "classSlug" was not supplied'),
    faction = console.warn('[Character] "faction" was not supplied'),
    isFavourite,
    onHoliday,
    lastOnlineAt,
    errorStartedAt,
  } = {}) {
    Object.assign(this, _.pickBy({
      battlenetId,
      id,
      realmId,
      name,
      classSlug,
      faction,
      isFavourite,
      onHoliday,
      lastOnlineAt,
      errorStartedAt,
    }, (field) => !_.$isEmpty(field)))
  }

  static fromApi ({ apiCharacter, battlenetId }) {
    return new Character({
      battlenetId,
      id: apiCharacter.id,
      name: apiCharacter.name,
      classSlug: _.kebabCase(apiCharacter.playableClass.name),
      realmId: apiCharacter.realm.id,
      faction: _.kebabCase(apiCharacter.faction.name),
    })
  }

  get nameSlug () {
    return this.name.toLowerCase()
  }

  get key () {
    return `${this.realm.region}-${this.realm.name}-${this.nameSlug}`
  }

  get realm () {
    return useWowStore().getRealmById(this.realmId)
  }

  get dbCharacter () {
    return _.pick(this, [
      'battlenetId',
      'id',
      'realmId',
      'name',
      'classSlug',
      'faction',
      'isFavourite',
      'onHoliday',
      'lastOnlineAt',
      'errorStartedAt',
    ])
  }
}
