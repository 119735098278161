<template>
  <AppIcon
    name="copy"
    :class="['copy-btn', {
      'copy-btn--right-tooltip': tooltipDirection === 'r',
      'copy-btn--copied': showTooltip,
      'copy-btn--fade': fadeTooltip,
    }]"
    :size="size"
    @click.stop="copyToClipBoard"/>
</template>

<script>
export default {
  props: {
    copyValue: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: Number,
      default: 20,
    },
    tooltipDirection: {
      type: String,
      validator: (direction) => ['l', 'r'].includes(direction),
      default: 'l',
    },
  },

  data: () => ({
    showTooltip: false,
    fadeTooltip: false,
  }),

  methods: {
    async copyToClipBoard () {
      this.showTooltip = true
      navigator.clipboard.writeText(this.copyValue)
      await _.$pause(700)
      this.fadeTooltip = true
      await _.$pause(400)
      this.fadeTooltip = false
      this.showTooltip = false
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.copy-btn {
  position: relative;
  color: rgb(var(--white) / 0.75);
  cursor: pointer;

  &:hover,
  &:focus {
    color: rgb(var(--white));
  }

  &--copied {
    &::after {
      content: "Copied!";
      position: absolute;
      top: calc(50% - 10px);
      right: calc(100% + 2px);
      align-self: center;
      padding: 0 8px;
      animation: fade-in 0.2s ease-out;
      border-radius: 4px;
      background: rgb(var(--white));
      color: rgb(var(--black));
      font-size: 12px;
      font-weight: 700;
    }

    &.copy-btn--right-tooltip::after {
      right: unset;
      left: calc(100% + 2px);
    }
  }

  &--fade::after {
    animation: fade-out 0.5s ease;
  }
}
</style>
