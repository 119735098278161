const BASE_PROPS = {
  skill: null,
  multicraftPercent: null,
  basePrice: null,
  baseConcentrationPrice: null,
  nonTierPrice: null,
  tier1Price: null,
  tier2Price: null,
  tier3Price: null,
  isActive: false,
  freeRecrafts: null,
  freeConcentration: null,
  providedReagentIds: [],
}

const ADDITIONAL_PROPS = {
  wasImported: false,
}

export default class Listing {
  constructor ({
    battlenetId = console.warn('[Listing] "battlenetId" was not supplied'),
    characterId = console.warn('[Listing] "characterId" was not supplied'),
    recipe = console.warn('[Listing] "recipe" was not supplied'),
    recipeId = recipe?.recipeId,
    id = self.crypto.randomUUID(),
    skill = null,
    multicraftPercent = null,
    basePrice = null,
    baseConcentrationPrice = null,
    nonTierPrice = null,
    tier1Price = null,
    tier2Price = null,
    tier3Price = null,
    canProvideNonTier = false,
    canProvideTier1 = false,
    canProvideTier2 = false,
    canProvideTier3 = false,
    isActive = false,
    freeRecrafts = null,
    freeConcentration = null,
    providedReagentIds = [],
    wasImported = false,
  } = {}) {
    const listing = {
      id,
      battlenetId,
      characterId,
      recipeId,
      recipe,
      skill,
      multicraftPercent,
      basePrice,
      baseConcentrationPrice,
      nonTierPrice,
      tier1Price,
      tier2Price,
      tier3Price,
      canProvideNonTier,
      canProvideTier1,
      canProvideTier2,
      canProvideTier3,
      isActive,
      freeRecrafts,
      freeConcentration,
      providedReagentIds: [],
      wasImported,
    }
    const baseProps = _.omit(BASE_PROPS, ['providedReagentIds'])
    Object.assign(this, {
      ...listing,
      cache: {
        ...baseProps,
        ..._.pick(listing, Object.keys(baseProps)),
        id,
        battlenetId,
        characterId,
        recipeId,
        providedReagentIds: [],
      },
    })

    this.cache.providedReagentIds = this.addReagentsProvided([...providedReagentIds]).sort()
  }

  get canRecraft () {
    return ['Weapon', 'Armor', 'Profession'].includes(this.recipe.item.type)
  }

  get hasTieredReagents () {
    return this.availableReagents.some((reagent) => _.isNumber(reagent.tier))
  }

  get forListingDB () {
    const listing = _.omit(this, [
      ...(_.isNull(this.id) ? ['id'] : []),
      'cache',
      'recipe',
      'providedReagentIds',
      'wasImported',
    ])
    return {
      ...listing,
      isActive: this.isActive || false,
    }
  }

  get forListingProvidedReagentsDB () {
    return [
      ..._.map(this.providedReagentIds, (providedReagentId) => ({
        listingId: this.id,
        reagentId: providedReagentId,
      })),
    ]
  }

  get forListingProvidedReagentsDBFromCache () {
    return [
      ..._.map(this.cache.providedReagentIds, (providedReagentId) => ({
        listingId: this.cache.id,
        reagentId: providedReagentId,
      })),
    ]
  }

  get availableReagents () {
    return [
      ..._.map(this.recipe.reagents, (reagent) => reagent.item),
      ..._.reduce(this.recipe.modifiedReagents, (acc, { modifiedReagent }) => {
        const { items } = modifiedReagent
        return [...acc, ..._.map(items, (item) => ({ ...item }))]
      }, []),
    ]
  }

  get availableReagentIds () {
    return _.map(this.availableReagents, 'id').sort()
  }

  get providedReagents () {
    if (_.isEmpty(this.providedReagentIds)) return []

    return [
      ..._([...this.recipe.modifiedReagents])
        .map(({ modifiedReagent }) => modifiedReagent.items)
        .flatten()
        .filter(({ id }) => this.providedReagentIds.includes(id))
        .value(),
      ..._([...this.recipe.reagents])
        .map('item')
        .flatten()
        .filter(({ id }) => this.providedReagentIds.includes(id))
        .value(),
    ]
  }

  get isValid () {
    const providedReagents = [...this.providedReagents ?? []]
    const allTieredReagentsHavePrices = _(providedReagents)
      .map(({ tier }) => _.isNull(tier) || !_.isNull(this[`tier${tier}Price`]))
      .every()
    const allNonTieredReagentsHavePrices = _(providedReagents)
      .map(({ tier }) => !_.isNull(tier) || !_.isNull(this.nonTierPrice))
      .every()

    // Is active listing
    if (this.isActive) {
      return _.every([
        this.recipe.maxQuality <= 1 || this.skill >= 1,
        _.isNull(this.recipe.maxSkill) || this.skill <= this.recipe.maxSkill,
        _.isNull(this.recipe.maxMulticraftPercent) || this.multicraftPercent <= this.recipe.maxMulticraftPercent,
        _.isNumber(this.basePrice),
        allTieredReagentsHavePrices,
        allNonTieredReagentsHavePrices,
      ])
    }

    // Is inactive listing
    return true
  }

  get hasChanges () {
    const newVal = _.pick(this, _.keys(BASE_PROPS))
    const oldVal = _.pick(this.cache, _.keys(BASE_PROPS))

    return !_.isEqual({ ...newVal }, { ...oldVal })
  }

  get isConfigured () {
    return this.getIsConfigured()
  }

  /**
   * if the default listing values === current listing values
   * isConfigured === false
   *
   * @returns {boolean}
   */
  getIsConfigured ({ version = this, excludedKeys = ['providedReagentIds'] } = {}) {
    const fields = _(version)
      .pick(_(BASE_PROPS).keys().without(...excludedKeys).value())
      .values()
      .value()
    const baseFields = _(BASE_PROPS)
      .omit(excludedKeys)
      .values()
      .value()

    return !_.isEqual({ ...fields }, { ...baseFields })
  }

  isProvidedNonTieredReagentInvalid () {
    return this.canProvideNonTier && _.some([
      _.isNull(this.basePrice),
      _.isNull(this.nonTierPrice),
    ])
  }

  isProvidedNonTieredReagentValid () {
    return this.canProvideNonTier && _.every([
      !_.isNull(this.basePrice),
      !_.isNull(this.nonTierPrice),
    ])
  }

  isProvidedTieredReagentInvalid (tier) {
    return this[`canProvideTier${tier}`] && _.some([
      _.isNull(this.basePrice),
      _.isNull(this[`tier${tier}Price`]),
    ])
  }

  isProvidedTieredReagentValid (tier) {
    return this[`canProvideTier${tier}`] && _.every([
      !_.isNull(this.basePrice),
      !_.isNull(this[`tier${tier}Price`]),
    ])
  }

  isTierProvided (tier) {
    return _.some(this.providedReagents, ['tier', tier])
  }

  isReagentProvided (reagentId) {
    return this.providedReagentIds.includes(reagentId)
  }

  addReagentsProvided (reagentsToAdd) {
    const ids = []
    for (const reagent of reagentsToAdd) {
      const id = _.isPlainObject(reagent) ? reagent.id : reagent
      const canListingUseReagent = this.availableReagentIds.includes(id)
      const doesListingProvideReagent = this.providedReagentIds.includes(id)

      if (!canListingUseReagent) continue

      if (!doesListingProvideReagent) {
        this.providedReagentIds.push(id)
        this.providedReagentIds.sort()
        ids.push(id)
      }

      _.isNumber(reagent?.tier)
        ? this[`canProvideTier${reagent.tier}`] = this.isTierProvided(reagent.tier)
        : this.canProvideNonTier = _.some(this.providedReagents, ({ tier }) => _.isNull(tier))
    }
    return ids
  }

  removeReagentsProvided (reagentsToRemove) {
    for (const reagent of reagentsToRemove) {
      const id = _.isPlainObject(reagent) ? reagent.id : reagent
      const canListingUseReagent = this.availableReagentIds.includes(id)
      const doesListingProvideReagent = this.providedReagentIds.includes(id)

      if (!canListingUseReagent) continue

      if (doesListingProvideReagent) {
        this.providedReagentIds = _.without(this.providedReagentIds, id)
        this.providedReagentIds.sort()
      }

      _.isNumber(reagent?.tier)
        ? this[`canProvideTier${reagent.tier}`] = this.isTierProvided(reagent.tier)
        : this.canProvideNonTier = _.some(this.providedReagents, ({ tier }) => _.isNull(tier))
    }
  }

  reset ({ clearReagents = false } = {}) {
    const props = { ..._.cloneDeep(BASE_PROPS), ..._.cloneDeep(ADDITIONAL_PROPS) }
    const propertyKeys = clearReagents
      ? _.keys(props)
      : _(props).keys().without('providedReagentIds').value()
    for (const key of propertyKeys) {
      this[key] = props[key]
    }
  }
}
