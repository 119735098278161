<template>
  <div :class="`alert alert--${type}`">
    <b
      v-if="title"
      class="alert__title">
      {{ title }}
    </b>
    <slot>
      <span
        v-if="content"
        class="alert__content"
        v-html="content"/>
    </slot>
    <AppIcon
      v-if="canClose"
      class="alert__close"
      name="close"
      @click="$emit('closed')"/>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      validator: (type) => ['info', 'error', 'success', 'warning'].includes(type),
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },

  emits: { closed: null },
}
</script>

<style lang="scss" scoped>
.alert {
  display: flex;
  align-items: center;
  padding: 0.25em 0.75em 0.25em 1em;
  border: 1px solid rgb(var(--white));
  border-radius: 4px;
  cursor: default;

  &:not(:first-child) {
    margin-top: 0.5em;
  }

  &__title + &__content {
    padding-left: 0.5em;
  }

  &__close {
    margin-left: 0.5em;
    transition: color 0.15s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
      color: rgb(var(--red-light-1));
    }
  }

  &--error {
    border-color: rgb(var(--red));
    background-color: rgb(var(--red-alert-bg));
  }

  &--warning {
    border-color: rgb(var(--orange));
    background-color: rgb(var(--orange-alert-bg));
  }

  &--success {
    border-color: rgb(var(--green));
    background-color: rgb(var(--green-alert-bg));
  }

  &--info {
    border-color: rgb(var(--blue));
    background-color: rgb(var(--blue-alert-bg));
  }
}
</style>
