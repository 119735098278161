import { callApi } from '../config'

const regions = import.meta.env.VITE_BATTLENET_REGIONS.split(',')

const URI = regions.reduce((acc, region) => ({
  ...acc,
  [region]: `https://${region}.api.blizzard.com`,
}), {})

// https://develop.battle.net/documentation/world-of-warcraft/profile-apis
export default {
  // Account Profile API :: GET Account Profile Summary
  async getProfile ({ region } = {}) {
    return await callApi({
      path: `${URI[region]}/profile/user/wow`,
      query: { namespace: `profile-${region}` },
    })
  },

  // Character Professions API :: GET Character Professions Summary
  async getProfessions ({ region, realm, characterName } = {}) {
    return await callApi({
      path: `${URI[region]}/profile/wow/character/${realm}/${characterName}/professions`,
      query: { namespace: `profile-${region}` },
    })
  },

  // Character Professions API :: GET Character Profile Summary
  async getCharacterData ({ region, realm, characterName } = {}) {
    return await callApi({
      path: `${URI[region]}/profile/wow/character/${realm}/${characterName}`,
      query: { namespace: `profile-${region}` },
    })
  },
}
