<template>
  <OnClickOutside @trigger="closeOptions">
    <AppButton
      :class="['auth-button', {
        'auth-button--logging-in': isLoggingIn,
        'auth-button--authenticated': isAuthenticated,
      }]"
      :theme="isAuthenticated ? 'slate' : 'blue'"
      @click.stop="handleBtnClick">
      <AppIcon
        class="auth-button__icon"
        name="battlenet"
        :size="20"/>
      <template v-if="isLoggingIn">
        <span>Logging in via <b>Battlenet</b></span>
      </template>

      <template v-else-if="!isAuthenticated">
        <span>Login with <b>Battlenet</b></span>
      </template>

      <template v-else>
        <span>
          <span class="battletag-name">{{ battleTag.name }}</span>
          <span class="battletag-number">#{{ battleTag.number }}</span>
        </span>
        <AppIcon
          v-if="isOnHoliday"
          name="holiday"/>
        <AppIcon
          :class="['auth-button__caret-icon', { 'auth-button__caret-icon--open': showOptions }]"
          name="caret"
          :size="20"/>
        <div
          v-if="showOptions"
          class="auth-button__options">
          <RouterLink
            class="option"
            :to="{ name: 'account' }"
            @click.stop="closeOptions">
            <AppIcon name="account"/>
            My Account
          </RouterLink>
          <RouterLink
            class="option option--listings"
            :to="{ name: 'listings' }"
            @click.stop="closeOptions">
            <AppIcon name="list"/>
            My Listings
            <span
              v-if="isOnHoliday"
              class="holiday-mode">On Holiday</span>
          </RouterLink>
          <RouterLink
            class="option"
            :to="{ name: 'logout' }"
            @click.stop="closeOptions">
            <AppIcon name="logout"/>
            Logout
          </RouterLink>
        </div>
      </template>
    </AppButton>
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from '@vueuse/components'
import { generateNonce } from '@/utils/api/battlenet/config'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores'
import { mapState } from 'pinia'

export default {
  components: {
    OnClickOutside,
  },

  setup: () => ({ route: useRoute() }),

  data: () => ({
    showOptions: false,
    hasClickedLogin: false,
  }),

  computed: {
    ...mapState(useUserStore, [
      'auth',
      'isAuthenticated',
      'isOnHoliday',
    ]),

    battleTag () {
      const [name, number] = this.auth.battleTag.split('#')
      return {
        name,
        number,
      }
    },

    isLoggingIn () {
      return !this.isAuthenticated && _.some([
        this.hasClickedLogin,
        location.href.includes('oauth/battlenet'),
      ])
    },
  },

  methods: {
    closeOptions () {
      this.showOptions = false
    },

    openOptions () {
      this.showOptions = true
    },

    handleBtnClick () {
      if (!this.isAuthenticated) {
        this.hasClickedLogin = true
        const { name, query, hash, path } = this.route
        localStorage.setItem('redirectRoute', JSON.stringify({ name, query, hash, path }))
        window.location.replace(`/.netlify/functions/auth?state=${generateNonce()}`)
        return
      }
      this.showOptions
        ? this.closeOptions()
        : this.openOptions()
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.auth-button {
  position: relative;
  z-index: 20;
  min-width: 234px;
  padding: 0.5em 1em !important;

  &__icon {
    margin-right: 0.25em;

    :deep(svg path) {
      fill: rgb(var(--white));
    }
  }

  &__caret-icon {
    margin-left: auto;
    transform: rotate(-90deg);
    transition: transform 0.1s ease;

    &--open {
      transform: rotate(0deg);
      transition: transform 0.25s ease;
    }
  }

  &__options {
    display: flex;
    position: absolute;
    top: 36px;
    right: 0;
    flex-direction: column;
    width: 100%;
    background: rgb(var(--grey-dark-3));
    box-shadow: 0 4px 8px rgb(var(--black) / 0.35);

    .option {
      display: flex;
      padding: 0.75em 1.5em;
      transition:
        color 0.25s ease-out,
        background 0.25s ease-out;
      background: inherit;
      color: rgb(var(--grey-light-1));
      gap: 8px;

      &--listings {
        .holiday-mode {
          color: rgb(var(--grey));
        }
      }

      &:hover {
        background: rgb(var(--white) / 0.01);
        color: rgb(var(--white));
      }
    }
  }

  &--logging-in {
    .auth-button {
      &__icon {
        animation: spin 4s linear infinite;
      }
    }
  }

  &--authenticated {
    .auth-button__icon {
      :deep(svg path) {
        fill: rgb(var(--blue));
      }
    }
  }
}

.battletag-number {
  color: rgb(var(--grey-light-1));
}
</style>
