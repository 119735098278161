import {
  BASE_ITEM_LEVEL_MODIFIERS as DF_BASE_ITEM_LEVEL_MODIFIERS,
  ITEM_LEVEL_MODIFIERS as DF_ITEM_LEVEL_MODIFIERS,
  TIER_3_ITEM_QUALITY_MODIFIER as DF_TIER_3_ITEM_QUALITY_MODIFIER,
} from '@/utils/config/expansions/dragonflight/reagentModifiers'
import {
  BASE_ITEM_LEVEL_MODIFIERS as TWW_BASE_ITEM_LEVEL_MODIFIERS,
  ITEM_LEVEL_MODIFIERS as TWW_ITEM_LEVEL_MODIFIERS,
  TIER_3_ITEM_QUALITY_MODIFIER as TWW_TIER_3_ITEM_QUALITY_MODIFIER,
} from '@/utils/config/expansions/thewarwithin/reagentModifiers'
import {
  WOW_EXP_DF,
  WOW_EXP_TWW,
} from '@/utils/config/expansions'

export const BASE_ITEM_LEVEL_MODIFIERS = {
  ...DF_BASE_ITEM_LEVEL_MODIFIERS,
  ...TWW_BASE_ITEM_LEVEL_MODIFIERS,
}

export const ITEM_LEVEL_MODIFIERS = {
  ...DF_ITEM_LEVEL_MODIFIERS,
  ...TWW_ITEM_LEVEL_MODIFIERS,
}

export const TIER_3_ITEM_QUALITY_MODIFIERS = {
  [WOW_EXP_DF]: DF_TIER_3_ITEM_QUALITY_MODIFIER,
  [WOW_EXP_TWW]: TWW_TIER_3_ITEM_QUALITY_MODIFIER,
}
