import { formatISO } from 'date-fns'

const torchOfPrimalAwakeningId = 47823
export const dummyListingId = torchOfPrimalAwakeningId

const listing = {
  isDummy: true,
  battlenetId: 101,
  characterId: 101,
  recipeId: dummyListingId,
  difficulty: 400,
  skill: 180,
  basePrice: 1000,
  isActive: true,
  freeRecrafts: true,
  canProvideNonTier: false,
  canProvideTier1: false,
  canProvideTier2: false,
  canProvideTier3: false,
  nonTierPrice: null,
  tier1Price: null,
  tier2Price: null,
  tier3Price: null,
  providedReagentIds: [],
  character: {
    lastOnlineAt: formatISO(new Date()),
    name: 'ATotalDummy',
    classSlug: 'priest',
  },
}

export default (realm, dummyIndex) => [
  {
    ...listing,
    character: {
      ...listing.character,
      realmId: realm.id,
    },
    id: 'dummy-listing-1',
  },
  {
    ...listing,
    character: {
      ...listing.character,
      realmId: realm.id,
    },
    id: 'dummy-listing-2',
    basePrice: 1000,
    canProvideNonTier: true,
    canProvideTier3: true,
    nonTierPrice: 250,
    tier3Price: 250,
    providedReagentIds: [
      194124, // vibrant shard
      200113, // resonant crystal
      194864, // tier 3: runed writhebark
    ],
  },
][dummyIndex]
